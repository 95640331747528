import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import i18n from './plugins/i18n';
import vuetify from './plugins/vuetify';
import store from './store'
import router from './router'
import './plugins/composition-api';
import './plugins/momet';

Vue.config.productionTip = false

new Vue({
  i18n,
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
