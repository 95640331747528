<template lang="pug">
    v-text-field(
        outlined
        @input="$emit('changed', $event)"
        :label="label"
        :type="showPassword ? 'text' : 'password'"
        :value="model"
        :error-messages="validationErrors[name]"
        :append-icon="showPassword ? 'visibility' : 'visibility_off'"
        @click:append="showPassword = !showPassword"
        :dense="true"
        :hint="hint"
    )
</template>

<script>
    import {useValidation} from '../../../composition/validation'

    export default {
        name: 'PasswordField',
        props: {
            label: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            hint: {
              type: String,
            },
            model: {}
        },
        model: {
            prop: 'model',
            event: 'changed'
        },
        setup() {
            const {validationErrors} = useValidation()
            return {validationErrors}
        },
        data() {
            return {
                showPassword: false,
            };
        },
    }
</script>
