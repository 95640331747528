<template lang="pug">
  v-textarea(
    outlined
    @input="$emit('changed', $event)"
    :label="label"
    :value="model"
    :error-messages="validationErrors[name]"
    :disabled="disabled"
    :dense="true"
    :hint="hint"
  )
</template>

<script>
import { useValidation } from '../../../composition/validation'

export default {
  name: 'TextareaField',
  props: {
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    disabled: {
      default: false
    },
    hint: {
      type: String,
    },
    model: {}
  },
  model: {
    prop: 'model',
    event: 'changed'
  },
  setup () {
    const { validationErrors } = useValidation();
    return { validationErrors }
  }
}
</script>
