<template lang="pug">
  v-flex.mx-auto(
    xs12
    sm8
    align-center
  )
    settings-form.mt-3(
      group="general"
    )
</template>

<script>
import SettingsForm from "../../components/settings/SettingsForm";
import { useNav } from "../../composition/nav"

export default {
  name: 'GeneralSettings',
  components: {SettingsForm},
  mounted() {
    const { navTitle } = useNav();
    navTitle.value = 'Общие настройки курсов'
  },
}
</script>
