<template lang="pug">
  v-form(
    @submit.prevent="submitHandler"
    v-if="items"
  )
    div(
      v-if="items.length"
    )
      component(
        v-for="(item, index) in items"
        :key="index"
        :is="item.type + '-field'"
        :label="item.name"
        :name="item.code"
        :type="['float', 'integer'].includes(item.type) ? 'number' : item.type"
        :valueType="item.type"
        :hint="item.description"
        :items="formatChoiceList(item.choseList || [])"
        item-text="text"
        item-value="value"
        v-model="item.value"
      )
      action-button
    div.text--disabled(
      v-else
    ) Записи отсутствуют.
</template>

<script>
import ActionButton from '@/components/form/buttons/ActionButton';
import {useMessage} from '@/composition/message';

export default {
  name: 'SettingsForm',
  props: {
    group: {
      type: String,
      required: true,
    },
    currency: {
      type: String,
    },
    direction: {
      type: String,
      default: null,
    },
    provider: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      items: null,
    }
  },
  computed: {
    codePrefix() {
      return `${this.group}${this.currency ? `.${this.currency}` : ''}${this.direction ? `.${this.direction}` : ''}${this.provider ? `.${this.provider}` : ''}___`;
    },
  },
  components: {
    ActionButton,
    'text-field': () => import('../../components/form/fields/TextareaField'),
    'string-field': () => import('../../components/form/fields/TextField'),
    'integer-field': () => import('../../components/form/fields/TextField'),
    'float-field': () => import('../../components/form/fields/TextField'),
    'array-field': () => import('../../components/form/fields/ArrayField'),
    'choice-field': () => import('../../components/form/fields/ChoiceField'),
    'boolean-field': () => import('../../components/form/fields/SwitchField'),
  },
  async mounted() {
    await this.fetchItems();
  },
  methods: {
    fetchItems() {
      return this.$axios.get('/settings', { params: { filter: { code: this.codePrefix } } }).then(({data: {data: items}}) => {
        this.items = items;
      });
    },
    submitHandler() {
      this.$axios.post('/settings', {data: this.items}).then(() => {
        const {success} = useMessage();
        success.value = 'Настройки успешно сохранены.';
        this.fetchItems();
        this.$emit('saved');
      })
    },
    formatChoiceList(items) {
      return items.map(item => ({
        text: this.$t(`course.sorting.${item}`),
        value: item,
      }))
    },
  },
  watch: {
    currency() {
      this.fetchItems();
    }
  },
}
</script>
