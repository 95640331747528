<template lang="pug">
  v-flex.mx-auto(
    xs12
    sm8
    align-center
  )
    settings(
      group="direction"
    )
</template>

<script>
import Settings from "../../components/settings/Settings";
import { useNav } from "../../composition/nav"

export default {
  name: 'CourseSettings',
  components: {Settings},
  mounted() {
    const { navTitle } = useNav();
    navTitle.value = 'Настройки купить/продать'
  },
}
</script>
