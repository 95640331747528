<template lang="pug">
  v-autocomplete(
    :items="items"
    :label="label"
    @change="onValueChange"
    :error-messages="validationErrors[name]"
    :value="model"
    outlined
    :item-text="itemText"
    :item-value="itemValue"
    :return-object="false"
    :dense="true"
    :clearable="true"
    :hide-details="hideDetails"
    :multiple="multiple"
    :hint="hint"
  )
</template>

<script>
  import { useValidation } from '../../../composition/validation'

  export default {
    name: 'SelectField',
    props: {
      label: {
        type: String,
      },
      name: {
        type: String,
        required: true
      },
      itemValue: {
        type: String,
        default: 'id'
      },
      itemText: {
        type: String,
        default: 'name'
      },
      hideDetails: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      },
      items: {
        type: Array,
      },
      hint: {
        type: String,
      },
      model: {}
    },
    model: {
      prop: 'model',
      event: 'changed'
    },
    setup () {
      const { validationErrors } = useValidation();
      return { validationErrors }
    },
      watch: {
          model: {
              handler (val) {
                  if (!val) {
                      return;
                  }

                  if (!this.multiple && typeof val === 'object') {
                      this.onValueChange(val[this.itemValue]);
                  }
              },
              immediate: true,
          }
      },
      methods: {
          onValueChange(val) {
              this.$emit('changed', val === undefined ? null : val)
          }
      },
  }
</script>
