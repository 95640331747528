<template lang="pug">
  v-switch(
    :label="label"
    v-model="switchModel"
    :error-messages="validationErrors[name]"
    :hint="hint"
  )
</template>

<script>
import { useValidation } from '../../../composition/validation'
import { computed } from '@vue/composition-api'

export default {
  name: 'SwitchField',
  props: {
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    hint: {
      type: String,
    },
    model: {}
  },
  model: {
    prop: 'model',
    event: 'changed'
  },
  setup (props, { emit }) {
    const { validationErrors } = useValidation();
    return {
      validationErrors,
      switchModel: computed({
        get: () => props.model,
        set: val => emit('changed', val)
      })
    }
  }
}
</script>
