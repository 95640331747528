import { computed } from '@vue/composition-api'
import store from '../store'

export function useMessage () {
  const error = computed({
    get: () => store.getters['message/error'],
    set: val => store.dispatch('message/setError', val)
  })
  const warning = computed({
    get: () => store.getters['message/warning'],
    set: val => store.dispatch('message/setWarning', val)
  })
  const success = computed({
    get: () => store.getters['message/success'],
    set: val => store.dispatch('message/setSuccess', val)
  })
  return { error, success, warning }
}
