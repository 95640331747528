<template lang="pug">
  v-btn(
    :dark="!loading"
    :disabled="loading"
    :color="color"
    type="submit"
    large
    @click="$emit('click')"
  )
    slot(name="icon")
    slot Сохранить
</template>

<script>
import { useLoading } from '../../../composition/loading'

export default {
  name: 'ActionButton',
  props: {
    color: {
      type: String,
      default: 'primary'
    }
  },
  setup () {
    const { circular: loading } = useLoading();
    return { loading }
  }
}
</script>
