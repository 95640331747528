<template lang="pug">
  v-row(
    v-if="course"
  )
    v-col(
      cols="12"
      sm="4"
    )
      v-card(
        outlined
      )
        v-card-text
          div.text-h6.font-weight-thin Текущий курс
            div(
              v-if="course.topPrice !== 0"
            )
              p.text-h4.success--text
                number-column(
                  :value="course.topPrice"
                  light
                )
            div.grey--text(
              v-else
            ) нет данных
      v-card.mt-3(
        outlined
      )
        v-card-text
          div.text-h6.font-weight-thin Планируемый курс
            div(
              v-if="course.plannedPrice !== 0"
            )
              p.text-h6.warning--text
                number-column(
                  :value="course.plannedPrice"
                  light
                )
            div.grey--text(
              v-else
            ) нет данных
          div.text-h6.font-weight-thin Курс поставщика
            div(
              v-if="course.providerPrice !== 0"
            )
              p.text-h6.text--primary
                number-column(
                  :value="course.providerPrice"
                  light
                )
            div.grey--text(
              v-else
            ) нет данных
          div.text-h6.font-weight-thin Средний курс
            div(
              v-if="course.medialCourse.price !== 0"
            )
              p.text-h6.text--primary
                number-column(
                  :value="course.medialCourse.price"
                  light
                )
            div.grey--text(
              v-else
            ) нет данных
          div.text-h6.font-weight-thin Процент отклонения
          p.text-h6.text--primary
            setting-value-column(
              :value="course.deviationPercent"
              use-currency-format
            )
          div.text-h6.font-weight-thin {{ course.settings.course_fix_percent.name }}
          p.text-h6.text--primary
            setting-value-column(
              :value="course.settings.course_fix_percent.value"
              use-currency-format
            )
      v-card.mt-3(
        outlined
      )
        v-card-text
          div.text-h6.font-weight-thin {{ course.settings.course_sort_direction.name }}
          p.text-h6.text--primary
            setting-value-column(
              :value="course.settings.course_sort_direction.value === 'default' ? undefined : $t(`course.sorting.${course.settings.course_sort_direction.value}`)"
            )
          div.text-h6.font-weight-thin {{ course.settings.course_position.name }}
          p.text-h6.text--primary
            setting-value-column(
              :value="course.settings.course_position.value !== undefined ? course.settings.course_position.value : undefined"
            )
          div.text-h6.font-weight-thin {{ course.settings.course_price_changing_sum.name }}
          p.text-h6.text--primary
            setting-value-column(
              :value="course.settings.course_price_changing_sum.value"
            )
          div.text-h6.font-weight-thin {{ course.settings.course_hold_time.name }}
          p.text-h6.text--primary
            setting-value-column(
              :value="course.settings.course_hold_time.value"
            )
    v-col(
      cols="12"
      sm="8"
    )
      p.text-h6.font-weight-thin Поставщики
      v-data-table.mb-3.elevation-1.provider-table(
        :headers="topCourseItemHeaders"
        :items="topCourseItems"
        :items-per-page="-1"
        disable-sort
        hide-default-footer
      )
        template(
          v-slot:item.code="{ item }"
        ) {{ $t(`provider.${item.code}`) }}
        template(
          v-slot:item.providerCurrentPrice="{ item }"
        )
          div(
            v-if="item.providerCurrentPrice !== 0"
          )
            number-column(
              :value="item.providerCurrentPrice"
              color="warning"
            )
          div.grey--text(
            v-else
          ) нет данных
        template(
          v-slot:item.correctionPercent="{ item }"
        )
          setting-value-column(
            :value="item.settings.course_correction_percent.value"
          )
        template(
          v-slot:item.priority="{ item }"
        )
          setting-value-column(
            :value="item.settings.provider_priority.value"
          )
        template(
          v-slot:item.providerFinalPrice="{ item }"
        )
          div(
            v-if="item.providerFinalPrice !== 0"
          )
            number-column(
              :value="item.providerFinalPrice"
              color="green"
            )
          div.grey--text(
            v-else
          ) нет данных
</template>

<script>
import NumberColumn from "./view/columns/NumberColumn";
import SettingValueColumn from './view/columns/SettingValueColumn';

export default {
  name: 'Course',
  components: {NumberColumn, SettingValueColumn},
  props: {
    direction: {
      required: true,
    },
    currency: {
      required: true,
    },
  },
  data() {
    return {
      course: null,
    }
  },
  mounted() {
    this.fetchCourse();
  },
  computed: {
    topCourseItems() {
      return this.course ? this.course.items : [];
    },
    topCourseItemHeaders() {
      return [
        {text: 'Название', value: 'code'},
        {text: this.topCourseItems[0].settings.provider_priority.name, value: 'priority', align: 'center'},
        {text: 'Текущий курс', value: 'providerCurrentPrice', align: 'center'},
        {text: this.topCourseItems[0].settings.course_correction_percent.name, value: 'correctionPercent', align: 'center'},
        {text: 'Финальный курс', value: 'providerFinalPrice', align: 'center'},
      ];
    },
  },
  methods: {
    fetchCourse() {
      return this.$axios.get(`/direction/${this.currency}/${this.direction}/top-course`).then(({data: {data: course}}) => {
        this.course = course;
      });
    }
  },
  watch: {
    currency() {
      this.fetchCourse();
    },
    direction() {
      this.fetchCourse();
    },
  }
}
</script>
