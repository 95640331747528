<template lang="pug">
  v-app.app
    v-navigation-drawer(
      fixed
      right
      temporary
      v-if="showNavigation"
      v-model="showNavigation"
    )
      v-list-item(
        v-if="token"
        exact
      )
        v-list-item-avatar
          v-icon(
            large
            color="blue darken-2"
          ) account_box
        v-list-item-content
          v-list-item-title {{ (user || {}).name }}
      v-divider
      v-list
        v-list-item(
          v-for="(navItem, i) in navItems"
          :key="`nav-item-${i}`"
          :to="navItem.to"
        )
          v-list-item-icon
            v-icon {{ navItem.ic || 'list' }}
          v-list-item-content
            v-list-item-title {{ navItem.name }}
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title Рубли
          v-list-item(v-for='(rubSetting, i) in rubSettings' :key='i' :to="rubSetting.to" link='')
            v-list-item-title(v-text='rubSetting.name')
            v-list-item-icon
              v-icon(v-text='rubSetting.ic')
                v-list-group(no-action='' sub-group='')
        //v-list-group(no-action='' sub-group='')
        //  template(v-slot:activator='')
        //    v-list-item-content
        //      v-list-item-title РубСим
        //  v-list-item(v-for='(rubSimSetting, i) in rubSimSettings' :key='i' :to="rubSimSetting.to" link='')
        //    v-list-item-title(v-text='rubSimSetting.name')
        //    v-list-item-icon
        //      v-icon(v-text='rubSimSetting.ic')
        //v-list-group(no-action='' sub-group='')
        //  template(v-slot:activator='')
        //    v-list-item-content
        //      v-list-item-title QIWI\BTC
        //  v-list-item(v-for='(qiwiBtcSetting, i) in qiwiBtcSettings' :key='i' :to="qiwiBtcSetting.to" link='')
        //    v-list-item-title(v-text='qiwiBtcSetting.name')
        //    v-list-item-icon
        //      v-icon(v-text='qiwiBtcSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title USDT\BTC
          v-list-item(v-for='(usdtBtcSetting, i) in usdtBtcSettings' :key='i' :to="usdtBtcSetting.to" link='')
            v-list-item-title(v-text='usdtBtcSetting.name')
            v-list-item-icon
              v-icon(v-text='usdtBtcSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title RUB\USDT
          v-list-item(v-for='(rubUsdtSetting, i) in rubUsdtSettings' :key='i' :to="rubUsdtSetting.to" link='')
            v-list-item-title(v-text='rubUsdtSetting.name')
            v-list-item-icon
              v-icon(v-text='rubUsdtSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title RUB\USD
          v-list-item(v-for='(rubUsdSetting, i) in rubUsdSettings' :key='i' :to="rubUsdSetting.to" link='')
            v-list-item-title(v-text='rubUsdSetting.name')
            v-list-item-icon
              v-icon(v-text='rubUsdSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title USD\EUR
          v-list-item(v-for='(usdEurSetting, i) in usdEurSettings' :key='i' :to="usdEurSetting.to" link='')
            v-list-item-title(v-text='usdEurSetting.name')
            v-list-item-icon
              v-icon(v-text='usdEurSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title USD\ETH
          v-list-item(v-for='(usdEthSetting, i) in usdEthSettings' :key='i' :to="usdEthSetting.to" link='')
            v-list-item-title(v-text='usdEthSetting.name')
            v-list-item-icon
              v-icon(v-text='usdEthSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title USD\BTC
          v-list-item(v-for='(usdBtcSetting, i) in usdBtcSettings' :key='i' :to="usdBtcSetting.to" link='')
            v-list-item-title(v-text='usdBtcSetting.name')
            v-list-item-icon
              v-icon(v-text='usdBtcSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title ETHs\BTC
          v-list-item(v-for='(ethBtcSetting, i) in ethBtcSettings' :key='i' :to="ethBtcSetting.to" link='')
            v-list-item-title(v-text='ethBtcSetting.name')
            v-list-item-icon
              v-icon(v-text='ethBtcSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WMZ\BTC
          v-list-item(v-for='(wmzBtcSetting, i) in wmzBtcSettings' :key='i' :to="wmzBtcSetting.to" link='')
            v-list-item-title(v-text='wmzBtcSetting.name')
            v-list-item-icon
              v-icon(v-text='wmzBtcSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title USDC\BTC
          v-list-item(v-for='(usdcBtcSetting, i) in usdcBtcSettings' :key='i' :to="usdcBtcSetting.to" link='')
            v-list-item-title(v-text='usdcBtcSetting.name')
            v-list-item-icon
              v-icon(v-text='usdcBtcSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHRUB\ETH
          v-list-item(v-for='(rubcashEthSetting, i) in rubcashEthSettings' :key='i' :to="rubcashEthSetting.to" link='')
            v-list-item-title(v-text='rubcashEthSetting.name')
            v-list-item-icon
              v-icon(v-text='rubcashEthSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHRUB\WMZ
          v-list-item(v-for='(rubcashWmzSetting, i) in rubcashWmzSettings' :key='i' :to="rubcashWmzSetting.to" link='')
            v-list-item-title(v-text='rubcashWmzSetting.name')
            v-list-item-icon
              v-icon(v-text='rubcashWmzSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHRUB\USDC
          v-list-item(v-for='(rubcashUsdcSetting, i) in rubcashUsdcSettings' :key='i' :to="rubcashUsdcSetting.to" link='')
            v-list-item-title(v-text='rubcashUsdcSetting.name')
            v-list-item-icon
              v-icon(v-text='rubcashUsdcSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title USDT\ETH
          v-list-item(v-for='(usdtEthSetting, i) in usdtEthSettings' :key='i' :to="usdtEthSetting.to" link='')
            v-list-item-title(v-text='usdtEthSetting.name')
            v-list-item-icon
              v-icon(v-text='usdtEthSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title USDT\WMZ
          v-list-item(v-for='(usdtWmzSetting, i) in usdtWmzSettings' :key='i' :to="usdtWmzSetting.to" link='')
            v-list-item-title(v-text='usdtWmzSetting.name')
            v-list-item-icon
              v-icon(v-text='usdtWmzSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title USDT\USDC
          v-list-item(v-for='(usdtUsdcSetting, i) in usdtUsdcSettings' :key='i' :to="usdtUsdcSetting.to" link='')
            v-list-item-title(v-text='usdtUsdcSetting.name')
            v-list-item-icon
            v-icon(v-text='usdtUsdcSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WMZ\ETH
          v-list-item(v-for='(wmzEthSetting, i) in wmzEthSettings' :key='i' :to="wmzEthSetting.to" link='')
            v-list-item-title(v-text='wmzEthSetting.name')
            v-list-item-icon
              v-icon(v-text='wmzEthSetting.ic')
        //v-list-group(no-action='' sub-group='')
        //  template(v-slot:activator='')
        //    v-list-item-content
        //      v-list-item-title RUB\BTC-GX
        //  v-list-item(v-for='(rubBtcGxSetting, i) in rubBtcGxSettings' :key='i' :to="rubBtcGxSetting.to" link='')
        //    v-list-item-title(v-text='rubBtcGxSetting.name')
        //    v-list-item-icon
        //      v-icon(v-text='rubBtcGxSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title SBERRUB\XMR
          v-list-item(v-for='(sberRubXmrSetting, i) in sberRubXmrSettings' :key='i' :to="sberRubXmrSetting.to" link='')
            v-list-item-title(v-text='sberRubXmrSetting.name')
            v-list-item-icon
              v-icon(v-text='sberRubXmrSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title TBRUB\XMR
          v-list-item(v-for='(tbRubXmrSetting, i) in tbRubXmrSettings' :key='i' :to="tbRubXmrSetting.to" link='')
            v-list-item-title(v-text='tbRubXmrSetting.name')
            v-list-item-icon
              v-icon(v-text='tbRubXmrSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CashInRub\XMR
          v-list-item(v-for='(accRubXmrSetting, i) in accRubXmrSettings' :key='i' :to="accRubXmrSetting.to" link='')
            v-list-item-title(v-text='accRubXmrSetting.name')
            v-list-item-icon
              v-icon(v-text='accRubXmrSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title BTC\XMR
          v-list-item(v-for='(btcXmrSetting, i) in btcXmrSettings' :key='i' :to="btcXmrSetting.to" link='')
            v-list-item-title(v-text='btcXmrSetting.name')
            v-list-item-icon
              v-icon(v-text='btcXmrSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CashInRub\BTC
          v-list-item(v-for='(accRubBtcSetting, i) in accRubBtcSettings' :key='i' :to="accRubBtcSetting.to" link='')
            v-list-item-title(v-text='accRubBtcSetting.name')
            v-list-item-icon
              v-icon(v-text='accRubBtcSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title BTC\TRX
          v-list-item(v-for='(btcTrxSetting, i) in btcTrxSettings' :key='i' :to="btcTrxSetting.to" link='')
            v-list-item-title(v-text='btcTrxSetting.name')
            v-list-item-icon
              v-icon(v-text='btcTrxSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title TRX\RUB
          v-list-item(v-for='(trxRubSetting, i) in trxRubSettings' :key='i' :to="trxRubSetting.to" link='')
            v-list-item-title(v-text='trxRubSetting.name')
            v-list-item-icon
              v-icon(v-text='trxRubSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title TRX\XMR
          v-list-item(v-for='(trxXmrSetting, i) in trxXmrSettings' :key='i' :to="trxXmrSetting.to" link='')
            v-list-item-title(v-text='trxXmrSetting.name')
            v-list-item-icon
              v-icon(v-text='trxXmrSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title TRX\SBP
          v-list-item(v-for='(trxSbpSetting, i) in trxSbpSettings' :key='i' :to="trxSbpSetting.to" link='')
            v-list-item-title(v-text='trxSbpSetting.name')
            v-list-item-icon
              v-icon(v-text='trxSbpSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title TRX\CashInRub
          v-list-item(v-for='(trxAccRubSetting, i) in trxAccRubSettings' :key='i' :to="trxAccRubSetting.to" link='')
            v-list-item-title(v-text='trxAccRubSetting.name')
            v-list-item-icon
              v-icon(v-text='trxAccRubSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title TRX\USDC
          v-list-item(v-for='(trxUsdcSetting, i) in trxUsdcSettings' :key='i' :to="trxUsdcSetting.to" link='')
            v-list-item-title(v-text='trxUsdcSetting.name')
            v-list-item-icon
              v-icon(v-text='trxUsdcSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title TRX\USDT
          v-list-item(v-for='(trxUsdtSetting, i) in trxUsdtSettings' :key='i' :to="trxUsdtSetting.to" link='')
            v-list-item-title(v-text='trxUsdtSetting.name')
            v-list-item-icon
              v-icon(v-text='trxUsdtSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title USD\USDT
          v-list-item(v-for='(usdUsdtSetting, i) in usdUsdtSettings' :key='i' :to="usdUsdtSetting.to" link='')
            v-list-item-title(v-text='usdUsdtSetting.name')
            v-list-item-icon
              v-icon(v-text='usdUsdtSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title USD\USDC
          v-list-item(v-for='(usdUsdcSetting, i) in usdUsdcSettings' :key='i' :to="usdUsdcSetting.to" link='')
            v-list-item-title(v-text='usdUsdcSetting.name')
            v-list-item-icon
              v-icon(v-text='usdUsdcSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title XMR\USD
          v-list-item(v-for='(xmrUsdSetting, i) in xmrUsdSettings' :key='i' :to="xmrUsdSetting.to" link='')
            v-list-item-title(v-text='xmrUsdSetting.name')
            v-list-item-icon
              v-icon(v-text='xmrUsdSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title TRX\USD
          v-list-item(v-for='(trxUsdSetting, i) in trxUsdSettings' :key='i' :to="trxUsdSetting.to" link='')
            v-list-item-title(v-text='trxUsdSetting.name')
            v-list-item-icon
              v-icon(v-text='trxUsdSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title USDC\RUB
          v-list-item(v-for='(usdcRubSetting, i) in usdcRubSettings' :key='i' :to="usdcRubSetting.to" link='')
            v-list-item-title(v-text='usdcRubSetting.name')
            v-list-item-icon
              v-icon(v-text='usdcRubSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title XMR\USDT
          v-list-item(v-for='(xmrUsdtSetting, i) in xmrUsdtSettings' :key='i' :to="xmrUsdtSetting.to" link='')
            v-list-item-title(v-text='xmrUsdtSetting.name')
            v-list-item-icon
              v-icon(v-text='xmrUsdtSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title XMR\USDC
          v-list-item(v-for='(xmrUsdcSetting, i) in xmrUsdcSettings' :key='i' :to="xmrUsdcSetting.to" link='')
            v-list-item-title(v-text='xmrUsdcSetting.name')
            v-list-item-icon
              v-icon(v-text='xmrUsdcSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CNY\RUB
          v-list-item(v-for='(cnyRubSetting, i) in cnyRubSettings' :key='i' :to="cnyRubSetting.to" link='')
            v-list-item-title(v-text='cnyRubSetting.name')
            v-list-item-icon
              v-icon(v-text='cnyRubSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CNY\CashInRub
          v-list-item(v-for='(cnyAccRubSetting, i) in cnyAccRubSettings' :key='i' :to="cnyAccRubSetting.to" link='')
            v-list-item-title(v-text='cnyAccRubSetting.name')
            v-list-item-icon
              v-icon(v-text='cnyAccRubSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CNY\BTC
          v-list-item(v-for='(cnyBtcSetting, i) in cnyBtcSettings' :key='i' :to="cnyBtcSetting.to" link='')
            v-list-item-title(v-text='cnyBtcSetting.name')
            v-list-item-icon
              v-icon(v-text='cnyBtcSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CNY\USD
          v-list-item(v-for='(cnyUsdSetting, i) in cnyUsdSettings' :key='i' :to="cnyUsdSetting.to" link='')
            v-list-item-title(v-text='cnyUsdSetting.name')
            v-list-item-icon
              v-icon(v-text='cnyUsdSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CNY\USDT
          v-list-item(v-for='(cnyUsdtSetting, i) in cnyUsdtSettings' :key='i' :to="cnyUsdtSetting.to" link='')
            v-list-item-title(v-text='cnyUsdtSetting.name')
            v-list-item-icon
              v-icon(v-text='cnyUsdtSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CNY\USDC
          v-list-item(v-for='(cnyUsdcSetting, i) in cnyUsdcSettings' :key='i' :to="cnyUsdcSetting.to" link='')
            v-list-item-title(v-text='cnyUsdcSetting.name')
            v-list-item-icon
              v-icon(v-text='cnyUsdcSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CNY\XMR
          v-list-item(v-for='(cnyXmrSetting, i) in cnyXmrSettings' :key='i' :to="cnyXmrSetting.to" link='')
            v-list-item-title(v-text='cnyXmrSetting.name')
            v-list-item-icon
              v-icon(v-text='cnyXmrSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CNY\TRX
          v-list-item(v-for='(cnyTrxSetting, i) in cnyTrxSettings' :key='i' :to="cnyTrxSetting.to" link='')
            v-list-item-title(v-text='cnyTrxSetting.name')
            v-list-item-icon
              v-icon(v-text='cnyTrxSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title USDT\CashInRub
          v-list-item(v-for='(usdtAccRubSetting, i) in usdtAccRubSettings' :key='i' :to="usdtAccRubSetting.to" link='')
            v-list-item-title(v-text='usdtAccRubSetting.name')
            v-list-item-icon
              v-icon(v-text='usdtAccRubSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title TUSD\RUB
          v-list-item(v-for='(tusdRubSetting, i) in tusdRubSettings' :key='i' :to="tusdRubSetting.to" link='')
            v-list-item-title(v-text='tusdRubSetting.name')
            v-list-item-icon
              v-icon(v-text='tusdRubSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title TUSD\CashInRub
          v-list-item(v-for='(tusdAccRubSetting, i) in tusdAccRubSettings' :key='i' :to="tusdAccRubSetting.to" link='')
            v-list-item-title(v-text='tusdAccRubSetting.name')
            v-list-item-icon
              v-icon(v-text='tusdAccRubSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title TUSD\BTC
          v-list-item(v-for='(tusdBtcSetting, i) in tusdBtcSettings' :key='i' :to="tusdBtcSetting.to" link='')
            v-list-item-title(v-text='tusdBtcSetting.name')
            v-list-item-icon
              v-icon(v-text='tusdBtcSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title TUSD\USD
          v-list-item(v-for='(tusdUsdSetting, i) in tusdUsdSettings' :key='i' :to="tusdUsdSetting.to" link='')
            v-list-item-title(v-text='tusdUsdSetting.name')
            v-list-item-icon
              v-icon(v-text='tusdUsdSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title TUSD\USDT
          v-list-item(v-for='(tusdUsdtSetting, i) in tusdUsdtSettings' :key='i' :to="tusdUsdtSetting.to" link='')
            v-list-item-title(v-text='tusdUsdtSetting.name')
            v-list-item-icon
              v-icon(v-text='tusdUsdtSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title TUSD\USDC
          v-list-item(v-for='(tusdUsdcSetting, i) in tusdUsdcSettings' :key='i' :to="tusdUsdcSetting.to" link='')
            v-list-item-title(v-text='tusdUsdcSetting.name')
            v-list-item-icon
              v-icon(v-text='tusdUsdcSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title TUSD\XMR
          v-list-item(v-for='(tusdXmrSetting, i) in tusdXmrSettings' :key='i' :to="tusdXmrSetting.to" link='')
            v-list-item-title(v-text='tusdXmrSetting.name')
            v-list-item-icon
              v-icon(v-text='tusdXmrSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title TUSD\TRX
          v-list-item(v-for='(tusdTrxSetting, i) in tusdTrxSettings' :key='i' :to="tusdTrxSetting.to" link='')
            v-list-item-title(v-text='tusdTrxSetting.name')
            v-list-item-icon
              v-icon(v-text='tusdTrxSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title TUSD\CNY
          v-list-item(v-for='(tusdCnySetting, i) in tusdCnySettings' :key='i' :to="tusdCnySetting.to" link='')
            v-list-item-title(v-text='tusdCnySetting.name')
            v-list-item-icon
              v-icon(v-text='tusdCnySetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title ALPCNY\RUB
          v-list-item(v-for='(alpCnyRubSetting, i) in alpCnyRubSettings' :key='i' :to="alpCnyRubSetting.to" link='')
            v-list-item-title(v-text='alpCnyRubSetting.name')
            v-list-item-icon
              v-icon(v-text='alpCnyRubSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title ALPCNY\CashInRub
          v-list-item(v-for='(alpCnyAccRubSetting, i) in alpCnyAccRubSettings' :key='i' :to="alpCnyAccRubSetting.to" link='')
            v-list-item-title(v-text='alpCnyAccRubSetting.name')
            v-list-item-icon
              v-icon(v-text='alpCnyAccRubSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title ALPCNY\BTC
          v-list-item(v-for='(alpCnyBtcSetting, i) in alpCnyBtcSettings' :key='i' :to="alpCnyBtcSetting.to" link='')
            v-list-item-title(v-text='alpCnyBtcSetting.name')
            v-list-item-icon
              v-icon(v-text='alpCnyBtcSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title ALPCNY\USD
          v-list-item(v-for='(alpCnyUsdSetting, i) in alpCnyUsdSettings' :key='i' :to="alpCnyUsdSetting.to" link='')
            v-list-item-title(v-text='alpCnyUsdSetting.name')
            v-list-item-icon
              v-icon(v-text='alpCnyUsdSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title ALPCNY\USDT
          v-list-item(v-for='(alpCnyUsdtSetting, i) in alpCnyUsdtSettings' :key='i' :to="alpCnyUsdtSetting.to" link='')
            v-list-item-title(v-text='alpCnyUsdtSetting.name')
            v-list-item-icon
              v-icon(v-text='alpCnyUsdtSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title ALPCNY\USDC
          v-list-item(v-for='(alpCnyUsdcSetting, i) in alpCnyUsdcSettings' :key='i' :to="alpCnyUsdcSetting.to" link='')
            v-list-item-title(v-text='alpCnyUsdcSetting.name')
            v-list-item-icon
              v-icon(v-text='alpCnyUsdcSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title ALPCNY\TUSD
          v-list-item(v-for='(alpCnyTusdSetting, i) in alpCnyTusdSettings' :key='i' :to="alpCnyTusdSetting.to" link='')
            v-list-item-title(v-text='alpCnyTusdSetting.name')
            v-list-item-icon
              v-icon(v-text='alpCnyTusdSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title ALPCNY\XMR
          v-list-item(v-for='(alpCnyXmrSetting, i) in alpCnyXmrSettings' :key='i' :to="alpCnyXmrSetting.to" link='')
            v-list-item-title(v-text='alpCnyXmrSetting.name')
            v-list-item-icon
              v-icon(v-text='alpCnyXmrSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title ALPCNY\TRX
          v-list-item(v-for='(alpCnyTrxSetting, i) in alpCnyTrxSettings' :key='i' :to="alpCnyTrxSetting.to" link='')
            v-list-item-title(v-text='alpCnyTrxSetting.name')
            v-list-item-icon
              v-icon(v-text='alpCnyTrxSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WCTCNY\RUB
          v-list-item(v-for='(wctCnyRubSetting, i) in wctCnyRubSettings' :key='i' :to="wctCnyRubSetting.to" link='')
            v-list-item-title(v-text='wctCnyRubSetting.name')
            v-list-item-icon
              v-icon(v-text='wctCnyRubSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WCTCNY\CashInRub
          v-list-item(v-for='(wctCnyAccRubSetting, i) in wctCnyAccRubSettings' :key='i' :to="wctCnyAccRubSetting.to" link='')
            v-list-item-title(v-text='wctCnyAccRubSetting.name')
            v-list-item-icon
              v-icon(v-text='wctCnyAccRubSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WCTCNY\BTC
          v-list-item(v-for='(wctCnyBtcSetting, i) in wctCnyBtcSettings' :key='i' :to="wctCnyBtcSetting.to" link='')
            v-list-item-title(v-text='wctCnyBtcSetting.name')
            v-list-item-icon
              v-icon(v-text='wctCnyBtcSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WCTCNY\USD
          v-list-item(v-for='(wctCnyUsdSetting, i) in wctCnyUsdSettings' :key='i' :to="wctCnyUsdSetting.to" link='')
            v-list-item-title(v-text='wctCnyUsdSetting.name')
            v-list-item-icon
              v-icon(v-text='wctCnyUsdSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WCTCNY\USDT
          v-list-item(v-for='(wctCnyUsdtSetting, i) in wctCnyUsdtSettings' :key='i' :to="wctCnyUsdtSetting.to" link='')
            v-list-item-title(v-text='wctCnyUsdtSetting.name')
            v-list-item-icon
              v-icon(v-text='wctCnyUsdtSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WCTCNY\USDC
          v-list-item(v-for='(wctCnyUsdcSetting, i) in wctCnyUsdcSettings' :key='i' :to="wctCnyUsdcSetting.to" link='')
            v-list-item-title(v-text='wctCnyUsdcSetting.name')
            v-list-item-icon
              v-icon(v-text='wctCnyUsdcSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WCTCNY\TUSD
          v-list-item(v-for='(wctCnyTusdSetting, i) in wctCnyTusdSettings' :key='i' :to="wctCnyTusdSetting.to" link='')
            v-list-item-title(v-text='wctCnyTusdSetting.name')
            v-list-item-icon
              v-icon(v-text='wctCnyTusdSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WCTCNY\XMR
          v-list-item(v-for='(wctCnyXmrSetting, i) in wctCnyXmrSettings' :key='i' :to="wctCnyXmrSetting.to" link='')
            v-list-item-title(v-text='wctCnyXmrSetting.name')
            v-list-item-icon
              v-icon(v-text='wctCnyXmrSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WCTCNY\TRX
          v-list-item(v-for='(wctCnyTrxSetting, i) in wctCnyTrxSettings' :key='i' :to="wctCnyTrxSetting.to" link='')
            v-list-item-title(v-text='wctCnyTrxSetting.name')
            v-list-item-icon
              v-icon(v-text='wctCnyTrxSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRECNY\RUB
          v-list-item(v-for='(wireCnyRubSetting, i) in wireCnyRubSettings' :key='i' :to="wireCnyRubSetting.to" link='')
            v-list-item-title(v-text='wireCnyRubSetting.name')
            v-list-item-icon
              v-icon(v-text='wireCnyRubSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRECNY\CashInRub
          v-list-item(v-for='(wireCnyAccRubSetting, i) in wireCnyAccRubSettings' :key='i' :to="wireCnyAccRubSetting.to" link='')
            v-list-item-title(v-text='wireCnyAccRubSetting.name')
            v-list-item-icon
              v-icon(v-text='wireCnyAccRubSetting.ic')
        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRECNY\BTC
          v-list-item(v-for='(wireCnyBtcSetting, i) in wireCnyBtcSettings' :key='i' :to="wireCnyBtcSetting.to" link='')
            v-list-item-title(v-text='wireCnyBtcSetting.name')
            v-list-item-icon
              v-icon(v-text='wireCnyBtcSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRECNY\USD
          v-list-item(v-for='(wireCnyUsdSetting, i) in wireCnyUsdSettings' :key='i' :to="wireCnyUsdSetting.to" link='')
            v-list-item-title(v-text='wireCnyUsdSetting.name')
            v-list-item-icon
              v-icon(v-text='wireCnyUsdSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRECNY\USDT
          v-list-item(v-for='(wireCnyUsdtSetting, i) in wireCnyUsdtSettings' :key='i' :to="wireCnyUsdtSetting.to" link='')
            v-list-item-title(v-text='wireCnyUsdtSetting.name')
            v-list-item-icon
              v-icon(v-text='wireCnyUsdtSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRECNY\USDC
          v-list-item(v-for='(wireCnyUsdcSetting, i) in wireCnyUsdcSettings' :key='i' :to="wireCnyUsdcSetting.to" link='')
            v-list-item-title(v-text='wireCnyUsdcSetting.name')
            v-list-item-icon
              v-icon(v-text='wireCnyUsdcSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRECNY\TUSD
          v-list-item(v-for='(wireCnyTusdSetting, i) in wireCnyTusdSettings' :key='i' :to="wireCnyTusdSetting.to" link='')
            v-list-item-title(v-text='wireCnyTusdSetting.name')
            v-list-item-icon
              v-icon(v-text='wireCnyTusdSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRECNY\XMR
          v-list-item(v-for='(wireCnyXmrSetting, i) in wireCnyXmrSettings' :key='i' :to="wireCnyXmrSetting.to" link='')
            v-list-item-title(v-text='wireCnyXmrSetting.name')
            v-list-item-icon
              v-icon(v-text='wireCnyXmrSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRECNY\TRX
          v-list-item(v-for='(wireCnyTrxSetting, i) in wireCnyTrxSettings' :key='i' :to="wireCnyTrxSetting.to" link='')
            v-list-item-title(v-text='wireCnyTrxSetting.name')
            v-list-item-icon
              v-icon(v-text='wireCnyTrxSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHCNY\RUB
          v-list-item(v-for='(cashCnyRubSetting, i) in cashCnyRubSettings' :key='i' :to="cashCnyRubSetting.to" link='')
            v-list-item-title(v-text='cashCnyRubSetting.name')
            v-list-item-icon
              v-icon(v-text='cashCnyRubSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHCNY\CashInRub
          v-list-item(v-for='(cashCnyAccRubSetting, i) in cashCnyAccRubSettings' :key='i' :to="cashCnyAccRubSetting.to" link='')
            v-list-item-title(v-text='cashCnyAccRubSetting.name')
            v-list-item-icon
              v-icon(v-text='cashCnyAccRubSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHCNY\BTC
          v-list-item(v-for='(cashCnyBtcSetting, i) in cashCnyBtcSettings' :key='i' :to="cashCnyBtcSetting.to" link='')
            v-list-item-title(v-text='cashCnyBtcSetting.name')
            v-list-item-icon
              v-icon(v-text='cashCnyBtcSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHCNY\USD
          v-list-item(v-for='(cashCnyUsdSetting, i) in cashCnyUsdSettings' :key='i' :to="cashCnyUsdSetting.to" link='')
            v-list-item-title(v-text='cashCnyUsdSetting.name')
            v-list-item-icon
              v-icon(v-text='cashCnyUsdSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHCNY\USDT
          v-list-item(v-for='(cashCnyUsdtSetting, i) in cashCnyUsdtSettings' :key='i' :to="cashCnyUsdtSetting.to" link='')
            v-list-item-title(v-text='cashCnyUsdtSetting.name')
            v-list-item-icon
              v-icon(v-text='cashCnyUsdtSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHCNY\USDC
          v-list-item(v-for='(cashCnyUsdcSetting, i) in cashCnyUsdcSettings' :key='i' :to="cashCnyUsdcSetting.to" link='')
            v-list-item-title(v-text='cashCnyUsdcSetting.name')
            v-list-item-icon
              v-icon(v-text='cashCnyUsdcSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHCNY\TUSD
          v-list-item(v-for='(cashCnyTusdSetting, i) in cashCnyTusdSettings' :key='i' :to="cashCnyTusdSetting.to" link='')
            v-list-item-title(v-text='cashCnyTusdSetting.name')
            v-list-item-icon
              v-icon(v-text='cashCnyTusdSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHCNY\XMR
          v-list-item(v-for='(cashCnyXmrSetting, i) in cashCnyXmrSettings' :key='i' :to="cashCnyXmrSetting.to" link='')
            v-list-item-title(v-text='cashCnyXmrSetting.name')
            v-list-item-icon
              v-icon(v-text='cashCnyXmrSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHCNY\TRX
          v-list-item(v-for='(cashCnyTrxSetting, i) in cashCnyTrxSettings' :key='i' :to="cashCnyTrxSetting.to" link='')
            v-list-item-title(v-text='cashCnyTrxSetting.name')
            v-list-item-icon
              v-icon(v-text='cashCnyTrxSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHUSD\RUB
          v-list-item(v-for='(cashUsdRubSetting, i) in cashUsdRubSettings' :key='i' :to="cashUsdRubSetting.to" link='')
            v-list-item-title(v-text='cashUsdRubSetting.name')
            v-list-item-icon
              v-icon(v-text='cashUsdRubSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHUSD\CashInRub
          v-list-item(v-for='(cashUsdAccRubSetting, i) in cashUsdAccRubSettings' :key='i' :to="cashUsdAccRubSetting.to" link='')
            v-list-item-title(v-text='cashUsdAccRubSetting.name')
            v-list-item-icon
              v-icon(v-text='cashUsdAccRubSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHUSD\BTC
          v-list-item(v-for='(cashUsdBtcSetting, i) in cashUsdBtcSettings' :key='i' :to="cashUsdBtcSetting.to" link='')
            v-list-item-title(v-text='cashUsdBtcSetting.name')
            v-list-item-icon
              v-icon(v-text='cashUsdBtcSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHUSD\USD
          v-list-item(v-for='(cashUsdUsdSetting, i) in cashUsdUsdSettings' :key='i' :to="cashUsdUsdSetting.to" link='')
            v-list-item-title(v-text='cashUsdUsdSetting.name')
            v-list-item-icon
              v-icon(v-text='cashUsdUsdSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHUSD\USDT
          v-list-item(v-for='(cashUsdUsdtSetting, i) in cashUsdUsdtSettings' :key='i' :to="cashUsdUsdtSetting.to" link='')
            v-list-item-title(v-text='cashUsdUsdtSetting.name')
            v-list-item-icon
              v-icon(v-text='cashUsdUsdtSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHUSD\USDC
          v-list-item(v-for='(cashUsdUsdcSetting, i) in cashUsdUsdcSettings' :key='i' :to="cashUsdUsdcSetting.to" link='')
            v-list-item-title(v-text='cashUsdUsdcSetting.name')
            v-list-item-icon
              v-icon(v-text='cashUsdUsdcSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHUSD\TUSD
          v-list-item(v-for='(cashUsdTusdSetting, i) in cashUsdTusdSettings' :key='i' :to="cashUsdTusdSetting.to" link='')
            v-list-item-title(v-text='cashUsdTusdSetting.name')
            v-list-item-icon
              v-icon(v-text='cashUsdTusdSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHUSD\XMR
          v-list-item(v-for='(cashUsdXmrSetting, i) in cashUsdXmrSettings' :key='i' :to="cashUsdXmrSetting.to" link='')
            v-list-item-title(v-text='cashUsdXmrSetting.name')
            v-list-item-icon
              v-icon(v-text='cashUsdXmrSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHUSD\TRX
          v-list-item(v-for='(cashUsdTrxSetting, i) in cashUsdTrxSettings' :key='i' :to="cashUsdTrxSetting.to" link='')
            v-list-item-title(v-text='cashUsdTrxSetting.name')
            v-list-item-icon
              v-icon(v-text='cashUsdTrxSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHUSD\CARDCNY
          v-list-item(v-for='(cashUsdCardCnySetting, i) in cashUsdCardCnySettings' :key='i' :to="cashUsdCardCnySetting.to" link='')
            v-list-item-title(v-text='cashUsdCardCnySetting.name')
            v-list-item-icon
              v-icon(v-text='cashUsdCardCnySetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHUSD\ALPCNY
          v-list-item(v-for='(cashUsdAlpCnySetting, i) in cashUsdAlpCnySettings' :key='i' :to="cashUsdAlpCnySetting.to" link='')
            v-list-item-title(v-text='cashUsdAlpCnySetting.name')
            v-list-item-icon
              v-icon(v-text='cashUsdAlpCnySetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHUSD\WCTCNY
          v-list-item(v-for='(cashUsdWctCnySetting, i) in cashUsdWctCnySettings' :key='i' :to="cashUsdWctCnySetting.to" link='')
            v-list-item-title(v-text='cashUsdWctCnySetting.name')
            v-list-item-icon
              v-icon(v-text='cashUsdWctCnySetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHUSD\WIRECNY
          v-list-item(v-for='(cashUsdWireCnySetting, i) in cashUsdWireCnySettings' :key='i' :to="cashUsdWireCnySetting.to" link='')
            v-list-item-title(v-text='cashUsdWireCnySetting.name')
            v-list-item-icon
              v-icon(v-text='cashUsdWireCnySetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHUSD\CASHCNY
          v-list-item(v-for='(cashUsdCashCnySetting, i) in cashUsdCashCnySettings' :key='i' :to="cashUsdCashCnySetting.to" link='')
            v-list-item-title(v-text='cashUsdCashCnySetting.name')
            v-list-item-icon
              v-icon(v-text='cashUsdCashCnySetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIREUSD\RUB
          v-list-item(v-for='(wireUsdRubSetting, i) in wireUsdRubSettings' :key='i' :to="wireUsdRubSetting.to" link='')
            v-list-item-title(v-text='wireUsdRubSetting.name')
            v-list-item-icon
              v-icon(v-text='wireUsdRubSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIREUSD\CashInRub
          v-list-item(v-for='(wireUsdAccRubSetting, i) in wireUsdAccRubSettings' :key='i' :to="wireUsdAccRubSetting.to" link='')
            v-list-item-title(v-text='wireUsdAccRubSetting.name')
            v-list-item-icon
              v-icon(v-text='wireUsdAccRubSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIREUSD\BTC
          v-list-item(v-for='(wireUsdBtcSetting, i) in wireUsdBtcSettings' :key='i' :to="wireUsdBtcSetting.to" link='')
            v-list-item-title(v-text='wireUsdBtcSetting.name')
            v-list-item-icon
              v-icon(v-text='wireUsdBtcSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIREUSD\USD
          v-list-item(v-for='(wireUsdUsdSetting, i) in wireUsdUsdSettings' :key='i' :to="wireUsdUsdSetting.to" link='')
            v-list-item-title(v-text='wireUsdUsdSetting.name')
            v-list-item-icon
              v-icon(v-text='wireUsdUsdSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIREUSD\USDT
          v-list-item(v-for='(wireUsdUsdtSetting, i) in wireUsdUsdtSettings' :key='i' :to="wireUsdUsdtSetting.to" link='')
            v-list-item-title(v-text='wireUsdUsdtSetting.name')
            v-list-item-icon
              v-icon(v-text='wireUsdUsdtSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIREUSD\USDC
          v-list-item(v-for='(wireUsdUsdcSetting, i) in wireUsdUsdcSettings' :key='i' :to="wireUsdUsdcSetting.to" link='')
            v-list-item-title(v-text='wireUsdUsdcSetting.name')
            v-list-item-icon
              v-icon(v-text='wireUsdUsdcSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIREUSD\TUSD
          v-list-item(v-for='(wireUsdTusdSetting, i) in wireUsdTusdSettings' :key='i' :to="wireUsdTusdSetting.to" link='')
            v-list-item-title(v-text='wireUsdTusdSetting.name')
            v-list-item-icon
              v-icon(v-text='wireUsdTusdSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIREUSD\XMR
          v-list-item(v-for='(wireUsdXmrSetting, i) in wireUsdXmrSettings' :key='i' :to="wireUsdXmrSetting.to" link='')
            v-list-item-title(v-text='wireUsdXmrSetting.name')
            v-list-item-icon
              v-icon(v-text='wireUsdXmrSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIREUSD\TRX
          v-list-item(v-for='(wireUsdTrxSetting, i) in wireUsdTrxSettings' :key='i' :to="wireUsdTrxSetting.to" link='')
            v-list-item-title(v-text='wireUsdTrxSetting.name')
            v-list-item-icon
              v-icon(v-text='wireUsdTrxSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIREUSD\CARDCNY
          v-list-item(v-for='(wireUsdCardCnySetting, i) in wireUsdCardCnySettings' :key='i' :to="wireUsdCardCnySetting.to" link='')
            v-list-item-title(v-text='wireUsdCardCnySetting.name')
            v-list-item-icon
              v-icon(v-text='wireUsdCardCnySetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIREUSD\ALPCNY
          v-list-item(v-for='(wireUsdAlpCnySetting, i) in wireUsdAlpCnySettings' :key='i' :to="wireUsdAlpCnySetting.to" link='')
            v-list-item-title(v-text='wireUsdAlpCnySetting.name')
            v-list-item-icon
              v-icon(v-text='wireUsdAlpCnySetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIREUSD\WCTCNY
          v-list-item(v-for='(wireUsdWctCnySetting, i) in wireUsdWctCnySettings' :key='i' :to="wireUsdWctCnySetting.to" link='')
            v-list-item-title(v-text='wireUsdWctCnySetting.name')
            v-list-item-icon
              v-icon(v-text='wireUsdWctCnySetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIREUSD\WIRECNY
          v-list-item(v-for='(wireUsdWireCnySetting, i) in wireUsdWireCnySettings' :key='i' :to="wireUsdWireCnySetting.to" link='')
            v-list-item-title(v-text='wireUsdWireCnySetting.name')
            v-list-item-icon
              v-icon(v-text='wireUsdWireCnySetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIREUSD\CASHCNY
          v-list-item(v-for='(wireUsdCashCnySetting, i) in wireUsdCashCnySettings' :key='i' :to="wireUsdCashCnySetting.to" link='')
            v-list-item-title(v-text='wireUsdCashCnySetting.name')
            v-list-item-icon
              v-icon(v-text='wireUsdCashCnySetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHRUB\SBERRUB
          v-list-item(v-for='(cashRubRubSetting, i) in cashRubRubSettings' :key='i' :to="cashRubRubSetting.to" link='')
            v-list-item-title(v-text='cashRubRubSetting.name')
            v-list-item-icon
              v-icon(v-text='cashRubRubSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHRUB\CashInRub
          v-list-item(v-for='(cashRubAccRubSetting, i) in cashRubAccRubSettings' :key='i' :to="cashRubAccRubSetting.to" link='')
            v-list-item-title(v-text='cashRubAccRubSetting.name')
            v-list-item-icon
              v-icon(v-text='cashRubAccRubSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHRUB\BTC
          v-list-item(v-for='(cashRubBtcSetting, i) in cashRubBtcSettings' :key='i' :to="cashRubBtcSetting.to" link='')
            v-list-item-title(v-text='cashRubBtcSetting.name')
            v-list-item-icon
              v-icon(v-text='cashRubBtcSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHRUB\USD
          v-list-item(v-for='(cashRubUsdSetting, i) in cashRubUsdSettings' :key='i' :to="cashRubUsdSetting.to" link='')
            v-list-item-title(v-text='cashRubUsdSetting.name')
            v-list-item-icon
              v-icon(v-text='cashRubUsdSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHRUB\CASHUSD
          v-list-item(v-for='(cashRubCashUsdSetting, i) in cashRubCashUsdSettings' :key='i' :to="cashRubCashUsdSetting.to" link='')
            v-list-item-title(v-text='cashRubCashUsdSetting.name')
            v-list-item-icon
              v-icon(v-text='cashRubCashUsdSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHRUB\USD
          v-list-item(v-for='(cashRubWireUsdSetting, i) in cashRubWireUsdSettings' :key='i' :to="cashRubWireUsdSetting.to" link='')
            v-list-item-title(v-text='cashRubWireUsdSetting.name')
            v-list-item-icon
              v-icon(v-text='cashRubWireUsdSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHRUB\USDT
          v-list-item(v-for='(cashRubUsdtSetting, i) in cashRubUsdtSettings' :key='i' :to="cashRubUsdtSetting.to" link='')
            v-list-item-title(v-text='cashRubUsdtSetting.name')
            v-list-item-icon
              v-icon(v-text='cashRubUsdtSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHRUB\USDC
          v-list-item(v-for='(cashRubUsdcSetting, i) in cashRubUsdcSettings' :key='i' :to="cashRubUsdcSetting.to" link='')
            v-list-item-title(v-text='cashRubUsdcSetting.name')
            v-list-item-icon
              v-icon(v-text='cashRubUsdcSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHRUB\TUSD
          v-list-item(v-for='(cashRubTusdSetting, i) in cashRubTusdSettings' :key='i' :to="cashRubTusdSetting.to" link='')
            v-list-item-title(v-text='cashRubTusdSetting.name')
            v-list-item-icon
              v-icon(v-text='cashRubTusdSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHRUB\XMR
          v-list-item(v-for='(cashRubXmrSetting, i) in cashRubXmrSettings' :key='i' :to="cashRubXmrSetting.to" link='')
            v-list-item-title(v-text='cashRubXmrSetting.name')
            v-list-item-icon
              v-icon(v-text='cashRubXmrSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHRUB\TRX
          v-list-item(v-for='(cashRubTrxSetting, i) in cashRubTrxSettings' :key='i' :to="cashRubTrxSetting.to" link='')
            v-list-item-title(v-text='cashRubTrxSetting.name')
            v-list-item-icon
              v-icon(v-text='cashRubTrxSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHRUB\CARDCNY
          v-list-item(v-for='(cashRubCardCnySetting, i) in cashRubCardCnySettings' :key='i' :to="cashRubCardCnySetting.to" link='')
            v-list-item-title(v-text='cashRubCardCnySetting.name')
            v-list-item-icon
              v-icon(v-text='cashRubCardCnySetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHRUB\ALPCNY
          v-list-item(v-for='(cashRubAlpCnySetting, i) in cashRubAlpCnySettings' :key='i' :to="cashRubAlpCnySetting.to" link='')
            v-list-item-title(v-text='cashRubAlpCnySetting.name')
            v-list-item-icon
              v-icon(v-text='cashRubAlpCnySetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHRUB\WCTCNY
          v-list-item(v-for='(cashRubWctCnySetting, i) in cashRubWctCnySettings' :key='i' :to="cashRubWctCnySetting.to" link='')
            v-list-item-title(v-text='cashRubWctCnySetting.name')
            v-list-item-icon
              v-icon(v-text='cashRubWctCnySetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHRUB\WIRECNY
          v-list-item(v-for='(cashRubWireCnySetting, i) in cashRubWireCnySettings' :key='i' :to="cashRubWireCnySetting.to" link='')
            v-list-item-title(v-text='cashRubWireCnySetting.name')
            v-list-item-icon
              v-icon(v-text='cashRubWireCnySetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title CASHRUB\CASHCNY
          v-list-item(v-for='(cashRubCashCnySetting, i) in cashRubCashCnySettings' :key='i' :to="cashRubCashCnySetting.to" link='')
            v-list-item-title(v-text='cashRubCashCnySetting.name')
            v-list-item-icon
              v-icon(v-text='cashRubCashCnySetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRERUB\SBERRUB
          v-list-item(v-for='(wireRubRubSetting, i) in wireRubRubSettings' :key='i' :to="wireRubRubSetting.to" link='')
            v-list-item-title(v-text='wireRubRubSetting.name')
            v-list-item-icon
              v-icon(v-text='wireRubRubSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRERUB\CashInRub
          v-list-item(v-for='(wireRubAccRubSetting, i) in wireRubAccRubSettings' :key='i' :to="wireRubAccRubSetting.to" link='')
            v-list-item-title(v-text='wireRubAccRubSetting.name')
            v-list-item-icon
              v-icon(v-text='wireRubAccRubSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRERUB\CashRub
          v-list-item(v-for='(wireRubCashRubSetting, i) in wireRubCashRubSettings' :key='i' :to="wireRubCashRubSetting.to" link='')
            v-list-item-title(v-text='wireRubCashRubSetting.name')
            v-list-item-icon
              v-icon(v-text='wireRubCashRubSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRERUB\BTC
          v-list-item(v-for='(wireRubBtcSetting, i) in wireRubBtcSettings' :key='i' :to="wireRubBtcSetting.to" link='')
            v-list-item-title(v-text='wireRubBtcSetting.name')
            v-list-item-icon
              v-icon(v-text='wireRubBtcSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRERUB\USD
          v-list-item(v-for='(wireRubUsdSetting, i) in wireRubUsdSettings' :key='i' :to="wireRubUsdSetting.to" link='')
            v-list-item-title(v-text='wireRubUsdSetting.name')
            v-list-item-icon
              v-icon(v-text='wireRubUsdSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRERUB\CASHUSD
          v-list-item(v-for='(wireRubCashUsdSetting, i) in wireRubCashUsdSettings' :key='i' :to="wireRubCashUsdSetting.to" link='')
            v-list-item-title(v-text='wireRubCashUsdSetting.name')
            v-list-item-icon
              v-icon(v-text='wireRubCashUsdSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRERUB\USD
          v-list-item(v-for='(wireRubWireUsdSetting, i) in wireRubWireUsdSettings' :key='i' :to="wireRubWireUsdSetting.to" link='')
            v-list-item-title(v-text='wireRubWireUsdSetting.name')
            v-list-item-icon
              v-icon(v-text='wireRubWireUsdSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRERUB\USDT
          v-list-item(v-for='(wireRubUsdtSetting, i) in wireRubUsdtSettings' :key='i' :to="wireRubUsdtSetting.to" link='')
            v-list-item-title(v-text='wireRubUsdtSetting.name')
            v-list-item-icon
              v-icon(v-text='wireRubUsdtSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRERUB\USDC
          v-list-item(v-for='(wireRubUsdcSetting, i) in wireRubUsdcSettings' :key='i' :to="wireRubUsdcSetting.to" link='')
            v-list-item-title(v-text='wireRubUsdcSetting.name')
            v-list-item-icon
              v-icon(v-text='wireRubUsdcSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRERUB\TUSD
          v-list-item(v-for='(wireRubTusdSetting, i) in wireRubTusdSettings' :key='i' :to="wireRubTusdSetting.to" link='')
            v-list-item-title(v-text='wireRubTusdSetting.name')
            v-list-item-icon
              v-icon(v-text='wireRubTusdSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRERUB\XMR
          v-list-item(v-for='(wireRubXmrSetting, i) in wireRubXmrSettings' :key='i' :to="wireRubXmrSetting.to" link='')
            v-list-item-title(v-text='wireRubXmrSetting.name')
            v-list-item-icon
              v-icon(v-text='wireRubXmrSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRERUB\TRX
          v-list-item(v-for='(wireRubTrxSetting, i) in wireRubTrxSettings' :key='i' :to="wireRubTrxSetting.to" link='')
            v-list-item-title(v-text='wireRubTrxSetting.name')
            v-list-item-icon
              v-icon(v-text='wireRubTrxSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRERUB\CARDCNY
          v-list-item(v-for='(wireRubCardCnySetting, i) in wireRubCardCnySettings' :key='i' :to="wireRubCardCnySetting.to" link='')
            v-list-item-title(v-text='wireRubCardCnySetting.name')
            v-list-item-icon
              v-icon(v-text='wireRubCardCnySetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRERUB\ALPCNY
          v-list-item(v-for='(wireRubAlpCnySetting, i) in wireRubAlpCnySettings' :key='i' :to="wireRubAlpCnySetting.to" link='')
            v-list-item-title(v-text='wireRubAlpCnySetting.name')
            v-list-item-icon
              v-icon(v-text='wireRubAlpCnySetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRERUB\WCTCNY
          v-list-item(v-for='(wireRubWctCnySetting, i) in wireRubWctCnySettings' :key='i' :to="wireRubWctCnySetting.to" link='')
            v-list-item-title(v-text='wireRubWctCnySetting.name')
            v-list-item-icon
              v-icon(v-text='wireRubWctCnySetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRERUB\WIRECNY
          v-list-item(v-for='(wireRubWireCnySetting, i) in wireRubWireCnySettings' :key='i' :to="wireRubWireCnySetting.to" link='')
            v-list-item-title(v-text='wireRubWireCnySetting.name')
            v-list-item-icon
              v-icon(v-text='wireRubWireCnySetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title WIRERUB\CASHCNY
          v-list-item(v-for='(wireRubCashCnySetting, i) in wireRubCashCnySettings' :key='i' :to="wireRubCashCnySetting.to" link='')
            v-list-item-title(v-text='wireRubCashCnySetting.name')
            v-list-item-icon
              v-icon(v-text='wireRubCashCnySetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title USDT\T-BANK
          v-list-item(v-for='(usdtTcsbrubSetting, i) in usdtTcsbrubSettings' :key='i' :to="usdtTcsbrubSetting.to" link='')
            v-list-item-title(v-text='usdtTcsbrubSetting.name')
            v-list-item-icon
              v-icon(v-text='usdtTcsbrubSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title BTC\T-BANK
          v-list-item(v-for='(btcTcsbrubSetting, i) in btcTcsbrubSettings' :key='i' :to="btcTcsbrubSetting.to" link='')
            v-list-item-title(v-text='btcTcsbrubSetting.name')
            v-list-item-icon
              v-icon(v-text='btcTcsbrubSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title XMR\T-BANK
          v-list-item(v-for='(xmrTcsbrubSetting, i) in xmrTcsbrubSettings' :key='i' :to="xmrTcsbrubSetting.to" link='')
            v-list-item-title(v-text='xmrTcsbrubSetting.name')
            v-list-item-icon
              v-icon(v-text='xmrTcsbrubSetting.ic')

        v-list-group(no-action='' sub-group='')
          template(v-slot:activator='')
            v-list-item-content
              v-list-item-title TRX\T-BANK
          v-list-item(v-for='(trxTcsbrubSetting, i) in trxTcsbrubSettings' :key='i' :to="trxTcsbrubSetting.to" link='')
            v-list-item-title(v-text='trxTcsbrubSetting.name')
            v-list-item-icon
              v-icon(v-text='trxTcsbrubSetting.ic')

        v-list-item(
          v-if="token"
          link
          @click="onLogoutClick"
        )
          v-list-item-icon
            v-icon exit_to_app
          v-list-item-content
            v-list-item-title Выход
    v-app-bar.app-bar(
      app
      dark
      color="primary"
    )
      v-btn(
        icon
        @click="$router.go(-1)"
        v-if="$route.name !== 'login'"
      )
        v-icon.icon-back arrow_back_ios
      v-toolbar-title {{ navTitle || 'Панель управления' }}
      v-spacer
      v-btn(
        icon
        to="/"
        exact
        v-if="$route.path !== '/' && $route.name !== 'login'"
       )
        v-icon home
      v-btn(
        icon
        v-if="$route.name !== 'login'"
        @click="showNavigation = !showNavigation"
      )
        v-icon more_vert
    v-main
      v-container(
        fluid
      )
        router-view
    v-progress-circular.progress-circular(
      v-if="loading"
      :size="50"
      color="primary"
      indeterminate
    )
    v-snackbar(
      v-if="snackbarMessage"
      v-model="snackbarMessage"
      :color="snackbarColor"
    ) {{ snackbarMessage }}
      v-btn(
        color="white"
        text
        icon
        @click="snackbar = false"
      )
        v-icon(
          dark
          @click="snackbarMessage = false"
        ) close
</template>

<script>
  import { useLoading } from './composition/loading'
  import { useMessage } from './composition/message'
  import { useAuth } from './composition/auth'
  import { useNav } from './composition/nav'
  import { computed } from '@vue/composition-api'
  import { mapActions } from 'vuex';

  export default {
    name: 'App',
    setup () {
      const { error, warning, success } = useMessage()
      const { circular: loading } = useLoading()
      const snackbarColor = computed(() => {
        if (error.value) return 'error'
        if (warning.value) return 'warning'
        if (success.value) return 'success'
      })
      const snackbarMessage = computed({
        get: () => {
          return error.value || warning.value || success.value
        },
        set: val => {
          if (!val) {
            error.value = warning.value = success.value = null
          }
        }
      })
      const { token, user } = useAuth()
      const {
        navTitle,
        navItems,
        rubSettings,
        rubSimSettings,
        qiwiBtcSettings,
        usdtBtcSettings,
        rubUsdtSettings,
        rubUsdSettings,
        usdEurSettings,
        usdEthSettings,
        usdBtcSettings,
        ethBtcSettings,
        wmzBtcSettings,
        usdcBtcSettings,
        rubcashEthSettings,
        rubcashWmzSettings,
        rubcashUsdcSettings,
        usdtEthSettings,
        usdtWmzSettings,
        usdtUsdcSettings,
        wmzEthSettings,
        rubBtcGxSettings,
        sberRubXmrSettings,
        tbRubXmrSettings,
        accRubXmrSettings,
        btcXmrSettings,
        accRubBtcSettings,
        btcTrxSettings,
        trxRubSettings,
        trxXmrSettings,
        trxSbpSettings,
        trxAccRubSettings,
        trxUsdcSettings,
        trxUsdtSettings,
        usdUsdtSettings,
        usdUsdcSettings,
        xmrUsdSettings,
        trxUsdSettings,
        usdcRubSettings,
        xmrUsdtSettings,
        xmrUsdcSettings,
        cnyRubSettings,
        cnyAccRubSettings,
        cnyBtcSettings,
        cnyUsdSettings,
        cnyUsdtSettings,
        cnyUsdcSettings,
        cnyXmrSettings,
        cnyTrxSettings,
        usdtAccRubSettings,
        tusdRubSettings,
        tusdAccRubSettings,
        tusdBtcSettings,
        tusdUsdSettings,
        tusdUsdtSettings,
        tusdUsdcSettings,
        tusdXmrSettings,
        tusdTrxSettings,
        tusdCnySettings,
        alpCnyRubSettings,
        alpCnyAccRubSettings,
        alpCnyBtcSettings,
        alpCnyUsdSettings,
        alpCnyUsdtSettings,
        alpCnyUsdcSettings,
        alpCnyTusdSettings,
        alpCnyXmrSettings,
        alpCnyTrxSettings,
        wctCnyRubSettings,
        wctCnyAccRubSettings,
        wctCnyBtcSettings,
        wctCnyUsdSettings,
        wctCnyUsdtSettings,
        wctCnyUsdcSettings,
        wctCnyTusdSettings,
        wctCnyXmrSettings,
        wctCnyTrxSettings,
        wireCnyRubSettings,
        wireCnyAccRubSettings,
        wireCnyBtcSettings,
        wireCnyUsdSettings,
        wireCnyUsdtSettings,
        wireCnyUsdcSettings,
        wireCnyTusdSettings,
        wireCnyXmrSettings,
        wireCnyTrxSettings,
        cashCnyRubSettings,
        cashCnyAccRubSettings,
        cashCnyBtcSettings,
        cashCnyUsdSettings,
        cashCnyUsdtSettings,
        cashCnyUsdcSettings,
        cashCnyTusdSettings,
        cashCnyXmrSettings,
        cashCnyTrxSettings,
        cashUsdRubSettings,
        cashUsdAccRubSettings,
        cashUsdBtcSettings,
        cashUsdUsdSettings,
        cashUsdUsdtSettings,
        cashUsdUsdcSettings,
        cashUsdTusdSettings,
        cashUsdXmrSettings,
        cashUsdTrxSettings,
        cashUsdCardCnySettings,
        cashUsdAlpCnySettings,
        cashUsdWctCnySettings,
        cashUsdWireCnySettings,
        cashUsdCashCnySettings,
        wireUsdRubSettings,
        wireUsdAccRubSettings,
        wireUsdBtcSettings,
        wireUsdUsdSettings,
        wireUsdUsdtSettings,
        wireUsdUsdcSettings,
        wireUsdTusdSettings,
        wireUsdXmrSettings,
        wireUsdTrxSettings,
        wireUsdCardCnySettings,
        wireUsdAlpCnySettings,
        wireUsdWctCnySettings,
        wireUsdWireCnySettings,
        wireUsdCashCnySettings,
        cashRubRubSettings,
        cashRubAccRubSettings,
        cashRubBtcSettings,
        cashRubUsdSettings,
        cashRubCashUsdSettings,
        cashRubWireUsdSettings,
        cashRubUsdtSettings,
        cashRubUsdcSettings,
        cashRubTusdSettings,
        cashRubXmrSettings,
        cashRubTrxSettings,
        cashRubCardCnySettings,
        cashRubAlpCnySettings,
        cashRubWctCnySettings,
        cashRubWireCnySettings,
        cashRubCashCnySettings,
        wireRubRubSettings,
        wireRubAccRubSettings,
        wireRubCashRubSettings,
        wireRubBtcSettings,
        wireRubUsdSettings,
        wireRubCashUsdSettings,
        wireRubWireUsdSettings,
        wireRubUsdtSettings,
        wireRubUsdcSettings,
        wireRubTusdSettings,
        wireRubXmrSettings,
        wireRubTrxSettings,
        wireRubCardCnySettings,
        wireRubAlpCnySettings,
        wireRubWctCnySettings,
        wireRubWireCnySettings,
        wireRubCashCnySettings,
        usdtTcsbrubSettings,
        btcTcsbrubSettings,
        xmrTcsbrubSettings,
        trxTcsbrubSettings,
      } = useNav()
      return {
        token,
        user,
        navTitle,
        navItems,
        rubSettings,
        rubSimSettings,
        qiwiBtcSettings,
        usdtBtcSettings,
        rubUsdtSettings,
        rubUsdSettings,
        usdEurSettings,
        usdEthSettings,
        usdBtcSettings,
        ethBtcSettings,
        wmzBtcSettings,
        usdcBtcSettings,
        rubcashEthSettings,
        rubcashWmzSettings,
        rubcashUsdcSettings,
        usdtEthSettings,
        usdtWmzSettings,
        usdtUsdcSettings,
        wmzEthSettings,
        rubBtcGxSettings,
        sberRubXmrSettings,
        tbRubXmrSettings,
        accRubXmrSettings,
        btcXmrSettings,
        accRubBtcSettings,
        btcTrxSettings,
        trxRubSettings,
        trxXmrSettings,
        trxSbpSettings,
        trxAccRubSettings,
        trxUsdcSettings,
        trxUsdtSettings,
        usdUsdtSettings,
        usdUsdcSettings,
        xmrUsdSettings,
        trxUsdSettings,
        usdcRubSettings,
        xmrUsdtSettings,
        xmrUsdcSettings,
        cnyRubSettings,
        cnyAccRubSettings,
        cnyBtcSettings,
        cnyUsdSettings,
        cnyUsdtSettings,
        cnyUsdcSettings,
        cnyXmrSettings,
        cnyTrxSettings,
        usdtAccRubSettings,
        tusdRubSettings,
        tusdAccRubSettings,
        tusdBtcSettings,
        tusdUsdSettings,
        tusdUsdtSettings,
        tusdUsdcSettings,
        tusdXmrSettings,
        tusdTrxSettings,
        tusdCnySettings,
        alpCnyRubSettings,
        alpCnyAccRubSettings,
        alpCnyBtcSettings,
        alpCnyUsdSettings,
        alpCnyUsdtSettings,
        alpCnyUsdcSettings,
        alpCnyTusdSettings,
        alpCnyXmrSettings,
        alpCnyTrxSettings,
        wctCnyRubSettings,
        wctCnyAccRubSettings,
        wctCnyBtcSettings,
        wctCnyUsdSettings,
        wctCnyUsdtSettings,
        wctCnyUsdcSettings,
        wctCnyTusdSettings,
        wctCnyXmrSettings,
        wctCnyTrxSettings,
        wireCnyRubSettings,
        wireCnyAccRubSettings,
        wireCnyBtcSettings,
        wireCnyUsdSettings,
        wireCnyUsdtSettings,
        wireCnyUsdcSettings,
        wireCnyTusdSettings,
        wireCnyXmrSettings,
        wireCnyTrxSettings,
        cashCnyRubSettings,
        cashCnyAccRubSettings,
        cashCnyBtcSettings,
        cashCnyUsdSettings,
        cashCnyUsdtSettings,
        cashCnyUsdcSettings,
        cashCnyTusdSettings,
        cashCnyXmrSettings,
        cashCnyTrxSettings,
        cashUsdRubSettings,
        cashUsdAccRubSettings,
        cashUsdBtcSettings,
        cashUsdUsdSettings,
        cashUsdUsdtSettings,
        cashUsdUsdcSettings,
        cashUsdTusdSettings,
        cashUsdXmrSettings,
        cashUsdTrxSettings,
        cashUsdCardCnySettings,
        cashUsdAlpCnySettings,
        cashUsdWctCnySettings,
        cashUsdWireCnySettings,
        cashUsdCashCnySettings,
        wireUsdRubSettings,
        wireUsdAccRubSettings,
        wireUsdBtcSettings,
        wireUsdUsdSettings,
        wireUsdUsdtSettings,
        wireUsdUsdcSettings,
        wireUsdTusdSettings,
        wireUsdXmrSettings,
        wireUsdTrxSettings,
        wireUsdCardCnySettings,
        wireUsdAlpCnySettings,
        wireUsdWctCnySettings,
        wireUsdWireCnySettings,
        wireUsdCashCnySettings,
        cashRubRubSettings,
        cashRubAccRubSettings,
        cashRubBtcSettings,
        cashRubUsdSettings,
        cashRubCashUsdSettings,
        cashRubWireUsdSettings,
        cashRubUsdtSettings,
        cashRubUsdcSettings,
        cashRubTusdSettings,
        cashRubXmrSettings,
        cashRubTrxSettings,
        cashRubCardCnySettings,
        cashRubAlpCnySettings,
        cashRubWctCnySettings,
        cashRubWireCnySettings,
        cashRubCashCnySettings,
        wireRubRubSettings,
        wireRubAccRubSettings,
        wireRubCashRubSettings,
        wireRubBtcSettings,
        wireRubUsdSettings,
        wireRubCashUsdSettings,
        wireRubWireUsdSettings,
        wireRubUsdtSettings,
        wireRubUsdcSettings,
        wireRubTusdSettings,
        wireRubXmrSettings,
        wireRubTrxSettings,
        wireRubCardCnySettings,
        wireRubAlpCnySettings,
        wireRubWctCnySettings,
        wireRubWireCnySettings,
        wireRubCashCnySettings,
        usdtTcsbrubSettings,
        btcTcsbrubSettings,
        xmrTcsbrubSettings,
        trxTcsbrubSettings,
        loading,
        snackbarMessage,
        snackbarColor,
      }
    },
    data() {
      return {
        showNavigation: false,
      }
    },
    watch: {
      token: {
        handler(value) {
          if (value) {
            this.$axios.get('/auth/current')
                .then(({ data: { data: user }}) => {
                  this.setUser(user);
                })
            ;
          } else {
            this.setUser(null);
          }
        },
        immediate: true,
      },
    },
    methods: {
      ...mapActions({
        clearAuth: 'auth/clearAuth',
        setUser: 'auth/setUser',
      }),
      onLogoutClick() {
        this.clearAuth();
        this.$router.push({ name: 'login' });
      },
    },
  }
</script>

<style lang="scss">
  .app {
    .app-bar {
      .icon-back {
        position: relative;
        right:-5px
      }
    }
    .progress-circular {
      position:fixed;
      top:50%;
      left:50%;
      margin:-25px 0 0 -25px;
      z-index:999999
    }
  }
  .hidden {
    display: none!important;
  }
  .v-data-table-header-mobile {
    display: none;
  }
</style>
