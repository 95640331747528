<template lang="pug">
    form-container
        v-form(
            @submit.prevent="formHandler"
        )
            text-field(
                label="Email"
                name="username"
                v-model="form.username"
            )
            password-field(
                label="Пароль"
                name="password"
                v-model="form.password"
            )
            v-alert(
                dense
                color="warning"
                border="left"
                v-if="error"
                dark
            ) {{ error }}
            div.text-end
                action-button Войти
</template>

<script>
    import FormContainer from "../components/form/FormContainer";
    import TextField from "../components/form/fields/TextField";
    import PasswordField from "../components/form/fields/PasswordField";
    import ActionButton from "../components/form/buttons/ActionButton";
    import { mapActions } from 'vuex';

    export default {
        name: 'Login',
        components: {ActionButton, PasswordField, TextField, FormContainer},
        data() {
            return {
                error: null,
                form: {
                    username: null,
                    password: null,
                },
            };
        },
        methods: {
            ...mapActions({
                setToken: 'auth/setToken',
                setExp: 'auth/setExp',
            }),
            formHandler() {
                this.error = null;
                this.$axios.post('/auth/jwt', {}, { headers: { 'X-AUTH-LOGIN': `${this.form.username}:${this.form.password}` } })
                    .then(({ data: { data: { accessToken: { exp, value: token } } } }) => {
                        this.setToken(token);
                        this.setExp(new Date(exp * 1000));
                        this.$router.push({ name: 'dashboard' })
                    })
                    .catch(() => {
                        this.error = 'Логин или пароль введены неверно.'
                    })
                ;
            },
        },
    }
</script>
