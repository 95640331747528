<template lang="pug">
  provider-grid(:currency="$route.params.currency")
</template>

<script>
import { useNav } from "../../composition/nav"
import ProviderGrid from "../../components/provider/ProviderGrid";
export default {
  name: 'ProviderList',
  components: {ProviderGrid},
  mounted() {
    const { navTitle } = useNav();
    navTitle.value = 'Поставщики курсов';
  },
}
</script>
