<template lang="pug">
  div
    v-container(fluid)
      v-row(dense)
        v-col(
          v-for="(currencyCode, index) in currencies"
          :key="currencyCode"
          cols="auto"
        )
          v-btn(
            :class="{'fixed-size-btn': true, 'active-btn': currencyTabModel === index}"
            @click="currencyTabModel = index"
          ) {{ $t(`currency.${currencyCode}`) }}
    v-divider
    v-container
      v-row(dense)
        v-col(
          v-for="(directionCode, index) in directions"
          :key="directionCode"
          cols="auto"
        )
          v-btn(
            :class="{'fixed-size-btn': true, 'active-btn': directionTabModel === index}"
            @click="directionTabModel = index"
          ) {{ $t(`direction.${directionCode}`) }}
    v-container(fluid)
      v-row
        v-col(cols="12")
          v-card(flat)
            v-flex.mx-auto(sm12 sm10 align-center)
              course.mt-3(
                v-if="direction"
                :direction="direction"
                :currency="currency"
              )
</template>

<style scoped>
.fixed-size-btn {
  width: 150px;
  height: 50px;
}

.active-btn {
  border: 2px solid #1976d2;
}
</style>

<script>
import { useNav } from '../composition/nav'
import Course from "../components/Course";
export default {
  name: 'Dashboard',
  components: { Course },
  setup () {
    const { navItems, navTitle } = useNav();
    navTitle.value = 'ТОП курс';
    return {
      navItems,
    }
  },
  data() {
    return {
      directions: [],
      currencies: [],
      directionTabModel: 0,
      currencyTabModel: 0,
    }
  },
  computed: {
    direction() {
      return this.directions[this.directionTabModel];
    },
    currency() {
      return this.currencies[this.currencyTabModel];
    },
  },
  created() {
    this.fetchDirections();
  },
  methods: {
    fetchDirections() {
      return this.$axios.get(`/direction`).then(({ data: { data: directions } }) => {
        this.directions = directions.direction;
        this.currencies = directions.currency;
      });
    },
  },
}
</script>