var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.providers.length)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.providers,"items-per-page":15},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex py-6"},[_c('v-chip',{staticClass:"mr-2 mt-1",staticStyle:{"width":"90px"},attrs:{"color":item.direction.code === 'sell' ? 'green' : 'warning',"outlined":"","x-small":""}},[_vm._v(_vm._s(_vm.$t(("direction." + (item.direction.code)))))]),_c('div',[_vm._v(_vm._s(_vm.$t(("provider." + (item.code)))))])],1)]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [(item.settings.provider_enabled.value)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("check")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("close")])]}},{key:"item.course",fn:function(ref){
var item = ref.item;
return [(item.course && item.course.price !== 0)?_c('div',[_c('div',{staticClass:"font-weight-thin",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(item.course.author))]),_c('number-column',{attrs:{"value":item.course.price,"color":"warning"}})],1):_c('div',{staticClass:"grey--text"},[_vm._v("нет данных")])]}},{key:"item.finalPrice",fn:function(ref){
var item = ref.item;
return [(item.finalPrice)?_c('div',[_c('div',{staticClass:"font-weight-thin",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(_vm._f("moment")(item.course.createdAt,"from", "now")))]),_c('number-column',{attrs:{"value":item.finalPrice,"color":"green"}})],1):_c('div',{staticClass:"grey--text"},[_vm._v("нет данных")])]}},{key:"item.correctionPercent",fn:function(ref){
var item = ref.item;
return [_c('setting-value-column',{attrs:{"value":item.settings.course_correction_percent.value}})]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('setting-value-column',{attrs:{"value":item.settings.provider_priority.value}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g({staticClass:"mx-1",on:{"click":function($event){return _vm.onSettingsIconClick(item)}}},on),[_vm._v("settings")])]}}],null,true)},[_c('span',[_vm._v("Настройки")])])]}}],null,false,3843616671)}):_vm._e(),(_vm.provider)?_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.settingsDialog),callback:function ($$v) {_vm.settingsDialog=$$v},expression:"settingsDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(_vm._s(("Настройки: " + (_vm.$t(("direction." + (_vm.provider.direction.code)))) + " " + (this.$t(("provider." + (_vm.provider.code)))))))]),_c('v-card-text',[_c('settings-form',{attrs:{"group":"provider","provider":_vm.provider.code,"direction":_vm.provider.direction.code,"currency":_vm.provider.currency},on:{"saved":_vm.onSettingsSavedHandler}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }