<template lang="pug">
  div.array-field.pa-10.mb-8
    div.label {{ label }}
    v-text-field(
      v-for="(value, index) in (model || [])"
      v-model="model[index]"
      :key="index"
      append-outer-icon="close"
      @click:append-outer="onDeleteClick(index)"
      :dense="true"
    )
    v-btn(
      text
      icon
      color="green"
      class="add"
      @click="onAddClick"
    )
      v-icon control_point
</template>

<script>
  import { useValidation } from '../../../composition/validation'

  export default {
    name: 'ArrayField',
    props: {
      label: {
        type: String,
        required: true
      },
      name: {
        type: String,
        required: true
      },
      model: {}
    },
    model: {
      prop: 'model',
      event: 'changed'
    },
    setup () {
      const { validationErrors } = useValidation();
      return { validationErrors }
    },
    methods: {
      onAddClick () {
        this.model.push('')
      },
      onDeleteClick (index) {
        this.model.splice(index, 1)
      }
    }
  }
</script>

<style lang="scss">
  .array-field {
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.87);
    min-height: 56px;
    border: 1px solid #bebebe;
    position: relative;
    .label {
      position: absolute;
      top: -11px;
      left: 9px;
      color: rgba(0, 0, 0, 0.54);
      height: 20px;
      line-height: 20px;
      background: #fff;
      font-size: 12px;
    }
    .v-text-field {
      padding: 0;
      margin: 0;
    }
    .add {
      position: absolute;
      bottom: 5px;
      right: 5px;
    }
  }
</style>
