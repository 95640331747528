<template lang="pug">
  v-flex.mx-auto(
      xs12
      sm6
      align-center
   )
    slot
</template>

<script>
export default {
  name: 'FormContainer',
}
</script>
