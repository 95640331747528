export default {
    direction: {
        buy: 'Покупка',
        sell: 'Продажа',
    },
    currency: {
        RUB: 'RUB/BTC',
        RUBSIM: 'RUBSIM/BTC',
        QIWI_BTC: 'QIWI/BTC',
        USDT_BTC: 'USDT/BTC',
        RUB_USDT: 'RUB/USDT',
        RUB_USD: 'RUB/USD',
        USD_EUR: 'USD/EUR',
        USD_ETH: 'USD/ETH',
        USD_BTC: 'USD/BTC',
        ETH_BTC: 'ETH/BTC',
        WMZ_BTC: 'WMZ/BTC',
        USDC_BTC: 'USDC/BTC',
        CASHRUB_ETH: 'CASHRUB/ETH',
        CASHRUB_USDC: 'CASHRUB/USDC',
        CASHRUB_WMZ: 'CASHRUB/WMZ',
        USDT_ETH: 'USDT/ETH',
        USDT_WMZ: 'USDT/WMZ',
        USDT_USDC: 'USDT/USDC',
        WMZ_ETH: 'WMZ/ETH',
        RUB_BTC_GX: 'RUB/BTC-GX',
        XMR_SBERRUB: 'SBERRUB/XMR',
        XMR_TBRUB: 'TBRUB/XMR',
        XMR_ACCRUB: 'CashInRub/XMR',
        XMR_BTC: 'BTC/XMR',
        ACCRUB_BTC: 'CashInRub/BTC',
        TRX_BTC: 'BTC/TRX',
        TRX_SBERRUB: 'TRX/RUB',
        TRX_XMR: 'TRX/XMR',
        TRX_SBPRUB: 'TRX/SBP',
        TRX_ACCRUB: 'TRX/CashInRub',
        TRX_USDC: 'TRX/USDC',
        TRX_USDTTRC20: 'TRX/USDT',
        CARDUSD_USDTTRC20: 'USD/USDT',
        CARDUSD_USDCTRC20: 'USD/USDC',
        XMR_CARDUSD: 'XMR/USD',
        TRX_CARDUSD: 'TRX/USD',
        USDCTRC20_SBERRUB: 'USDC/RUB',
        XMR_USDTTRC20: 'XMR/USDT',
        XMR_USDCTRC20: 'XMR/USDC',
        CARDCNY_SBERRUB: 'CNY/RUB',
        CARDCNY_ACCRUB: 'CNY/CashInRub',
        CARDCNY_BTC: 'CNY/BTC',
        CARDCNY_CARDUSD: 'CNY/USD',
        CARDCNY_USDTTRC20: 'CNY/USDT',
        CARDCNY_USDCTRC20: 'CNY/USDC',
        CARDCNY_XMR: 'CNY/XMR',
        CARDCNY_TRX: 'CNY/TRX',
        USDTTRC20_ACCRUB: 'USDT/CashInRub',
        TUSDTRC20_SBERRUB: 'TUSD/RUB',
        TUSDTRC20_ACCRUB: 'TUSD/CashInRub',
        TUSDTRC20_BTC: 'TUSD/BTC',
        TUSDTRC20_CARDUSD: 'TUSD/USD',
        TUSDTRC20_USDTTRC20: 'TUSD/USDT',
        TUSDTRC20_USDCTRC20: 'TUSD/USDC',
        TUSDTRC20_XMR: 'TUSD/XMR',
        TUSDTRC20_TRX: 'TUSD/TRX',
        TUSDTRC20_CARDCNY: 'TUSD/CNY',
        ALPCNY_SBERRUB: 'ALPCNY/RUB',
        ALPCNY_ACCRUB: 'ALPCNY/CashInRub',
        ALPCNY_BTC: 'ALPCNY/BTC',
        ALPCNY_CARDUSD: 'ALPCNY/USD',
        ALPCNY_USDTTRC20: 'ALPCNY/USDT',
        ALPCNY_USDCTRC20: 'ALPCNY/USDC',
        ALPCNY_TUSDTRC20: 'ALPCNY/TUSD',
        ALPCNY_XMR: 'ALPCNY/XMR',
        ALPCNY_TRX: 'ALPCNY/TRX',
        WCTCNY_SBERRUB: 'WCTCNY/RUB',
        WCTCNY_ACCRUB: 'WCTCNY/CashInRub',
        WCTCNY_BTC: 'WCTCNY/BTC',
        WCTCNY_CARDUSD: 'WCTCNY/USD',
        WCTCNY_USDTTRC20: 'WCTCNY/USDT',
        WCTCNY_USDCTRC20: 'WCTCNY/USDC',
        WCTCNY_TUSDTRC20: 'WCTCNY/TUSD',
        WCTCNY_XMR: 'WCTCNY/XMR',
        WCTCNY_TRX: 'WCTCNY/TRX',
        WIRECNY_SBERRUB: 'WIRECNY/RUB',
        WIRECNY_ACCRUB: 'WIRECNY/CashInRub',
        WIRECNY_BTC: 'WIRECNY/BTC',
        WIRECNY_CARDUSD: 'WIRECNY/USD',
        WIRECNY_USDTTRC20: 'WIRECNY/USDT',
        WIRECNY_USDCTRC20: 'WIRECNY/USDC',
        WIRECNY_TUSDTRC20: 'WIRECNY/TUSD',
        WIRECNY_XMR: 'WIRECNY/XMR',
        WIRECNY_TRX: 'WIRECNY/TRX',
        CASHCNY_SBERRUB: 'CASHCNY/RUB',
        CASHCNY_ACCRUB: 'CASHCNY/CashInRub',
        CASHCNY_BTC: 'CASHCNY/BTC',
        CASHCNY_CARDUSD: 'CASHCNY/USD',
        CASHCNY_USDTTRC20: 'CASHCNY/USDT',
        CASHCNY_USDCTRC20: 'CASHCNY/USDC',
        CASHCNY_TUSDTRC20: 'CASHCNY/TUSD',
        CASHCNY_XMR: 'CASHCNY/XMR',
        CASHCNY_TRX: 'CASHCNY/TRX',
        CASHUSD_SBERRUB: 'CASHUSD/RUB',
        CASHUSD_ACCRUB: 'CASHUSD/CashInRub',
        CASHUSD_BTC: 'CASHUSD/BTC',
        CASHUSD_CARDUSD: 'CASHUSD/USD',
        CASHUSD_USDTTRC20: 'CASHUSD/USDT',
        CASHUSD_USDCTRC20: 'CASHUSD/USDC',
        CASHUSD_TUSDTRC20: 'CASHUSD/TUSD',
        CASHUSD_XMR: 'CASHUSD/XMR',
        CASHUSD_TRX: 'CASHUSD/TRX',
        CASHUSD_CARDCNY: 'CASHUSD/CARDCNY',
        CASHUSD_ALPCNY: 'CASHUSD/ALPCNY',
        CASHUSD_WCTCNY: 'CASHUSD/WCTCNY',
        CASHUSD_WIRECNY: 'CASHUSD/WIRECNY',
        CASHUSD_CASHCNY: 'CASHUSD/CASHCNY',
        WIREUSD_SBERRUB: 'WIREUSD/RUB',
        WIREUSD_ACCRUB: 'WIREUSD/CashInRub',
        WIREUSD_BTC: 'WIREUSD/BTC',
        WIREUSD_CARDUSD: 'WIREUSD/USD',
        WIREUSD_USDTTRC20: 'WIREUSD/USDT',
        WIREUSD_USDCTRC20: 'WIREUSD/USDC',
        WIREUSD_TUSDTRC20: 'WIREUSD/TUSD',
        WIREUSD_XMR: 'WIREUSD/XMR',
        WIREUSD_TRX: 'WIREUSD/TRX',
        WIREUSD_CARDCNY: 'WIREUSD/CARDCNY',
        WIREUSD_ALPCNY: 'WIREUSD/ALPCNY',
        WIREUSD_WCTCNY: 'WIREUSD/WCTCNY',
        WIREUSD_WIRECNY: 'WIREUSD/WIRECNY',
        WIREUSD_CASHCNY: 'WIREUSD/CASHCNY',
        CASHRUB_SBERRUB: 'CASHRUB/SBERRUB',
        CASHRUB_ACCRUB: 'CASHRUB/CashInRub',
        CASHRUB_BTC: 'CASHRUB/BTC',
        CASHRUB_CARDUSD: 'CASHRUB/USD',
        CASHRUB_CASHUSD: 'CASHRUB/CASHUSD',
        CASHRUB_WIREUSD: 'CASHRUB/WIREUSD',
        CASHRUB_USDTTRC20: 'CASHRUB/USDT',
        CASHRUB_USDCTRC20: 'CASHRUB/USDC',
        CASHRUB_TUSDTRC20: 'CASHRUB/TUSD',
        CASHRUB_XMR: 'CASHRUB/XMR',
        CASHRUB_TRX: 'CASHRUB/TRX',
        CASHRUB_CARDCNY: 'CASHRUB/CARDCNY',
        CASHRUB_ALPCNY: 'CASHRUB/ALPCNY',
        CASHRUB_WCTCNY: 'CASHRUB/WCTCNY',
        CASHRUB_WIRECNY: 'CASHRUB/WIRECNY',
        CASHRUB_CASHCNY: 'CASHRUB/CASHCNY',
        WIRERUB_SBERRUB: 'WIRERUB/SBERRUB',
        WIRERUB_ACCRUB: 'WIRERUB/CashInRub',
        WIRERUB_CASHRUB: 'WIRERUB/CASHRUB',
        WIRERUB_BTC: 'WIRERUB/BTC',
        WIRERUB_CARDUSD: 'WIRERUB/USD',
        WIRERUB_CASHUSD: 'WIRERUB/CASHUSD',
        WIRERUB_WIREUSD: 'WIRERUB/WIREUSD',
        WIRERUB_USDTTRC20: 'WIRERUB/USDT',
        WIRERUB_USDCTRC20: 'WIRERUB/USDC',
        WIRERUB_TUSDTRC20: 'WIRERUB/TUSD',
        WIRERUB_XMR: 'WIRERUB/XMR',
        WIRERUB_TRX: 'WIRERUB/TRX',
        WIRERUB_CARDCNY: 'WIRERUB/CARDCNY',
        WIRERUB_ALPCNY: 'WIRERUB/ALPCNY',
        WIRERUB_WCTCNY: 'WIRERUB/WCTCNY',
        WIRERUB_WIRECNY: 'WIRERUB/WIRECNY',
        WIRERUB_CASHCNY: 'WIRERUB/CASHCNY',

        USDT_TCSBRUB: 'USDT/T-BANK',
        BTC_TCSBRUB: 'BTC/T-BANK',
        XMR_TCSBRUB: 'XMR/T-BANK',
        TRX_TCSBRUB: 'TRX/T-BANK',
    },
    course: {
        sorting: {
            default: 'по умолчанию',
            course_up: 'от меньшего к большему',
            course_down: 'от большего к меньшему',
            provider_priority: 'по приоритету поставщика',
        },
    },
    provider: {
        // LBC_QIWI: 'LBC QIWI',
        // LBC_C2C: 'LBC C2C',
        // LBC_NATIONAL: 'LBC National',
        // LBC_YANDEX: 'LBC yandex',
        // LBC_TRANSFER_SPEC_BANK: 'LBC Transfer spec bank',
        // LBC_MEDIAL: 'LBC btc_in_usd*USD_in_RUB',
        // BINANCE: 'Binance',
        // HUOBI: 'Huobi',
        // WB: 'wm.exchanger',
        // BC_SBERBANK: 'BC Сбербанк',
        // BC_CASH: 'BC наличные',
        // BC_QIWI: 'BC QIWI',
    },
};
