const state = () => ({
  token: null,
  exp: null,
  user: null,
})

const mutations = {
  token (state, token) {
    token ? localStorage.setItem('token', token) : localStorage.removeItem('token')
    state.token = token
  },
  exp (state, exp) {
    exp ? localStorage.setItem('exp', exp) : localStorage.removeItem('exp')
    state.exp = exp
  },
  user (state, user) {
    state.user = user
  },
}

const actions = {
  setToken ({ commit }, token) {
    commit('token', token);
  },
  setExp ({ commit }, exp) {
    commit('exp', exp);
  },
  setUser ({ commit }, user) {
    commit('user', user);
  },
  clearAuth ({ dispatch, commit }) {
    commit('token', null);
    commit('exp', null);
    dispatch('setUser', null);
  },
  fetchAuth ({ dispatch, commit }) {
    const token = localStorage.getItem('token');
    if (!token) {
      return
    }
    const exp = new Date(localStorage.getItem('exp'));
    const isTokenExpired = !exp || new Date() > exp;
    if (isTokenExpired) {
      dispatch('clearAuth');
      return
    }
    commit('token', token);
    commit('exp', exp);
  },
}

const getters = {
  token: state => state.token,
  exp: state => state.exp,
  user: state => state.user,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
