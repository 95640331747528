<template lang="pug">
  div
    span(
      v-if="value !== undefined"
    )
      number-column(
        v-if="useCurrencyFormat"
        :value="value"
        light
      )
      span(
        v-else
      ) {{ value }}
    span.font-italic.grey--text(
      v-else
    ) не установлено
</template>

<script>
import NumberColumn from "./NumberColumn";

export default {
  name: 'SettingValueColumn',
  components: {NumberColumn},
  props: {
    value: {},
    useCurrencyFormat: {
      type: Boolean,
      default: false,
    },
  }
}
</script>
