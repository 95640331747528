<template lang="pug">
  v-text-field(
    outlined
    @input="onInputHandler"
    :label="label"
    :type="type"
    :value="model"
    :disabled="disabled"
    :readonly="readonly"
    :error-messages="validationErrors[name]"
    :dense="true"
    :hint="hint"
    :hide-details="hideDetails && !(validationErrors[name] || []).length"
  )
</template>

<script>
  import { useValidation } from '../../../composition/validation'

  export default {
    name: 'TextField',
    props: {
      label: {
        type: String,
      },
      name: {
        type: String,
        required: true
      },
      disabled: {
        default: false
      },
      readonly: {
        default: false
      },
      hideDetails: {
        default: false
      },
      type: {
        default: 'text'
      },
      valueType: {
        type: String,
        default: 'text'
      },
      hint: {
        type: String,
      },
      model: {}
    },
    model: {
      prop: 'model',
      event: 'changed'
    },
    setup () {
      const { validationErrors } = useValidation();
      return { validationErrors }
    },
    methods: {
      onInputHandler(val) {
        this.$emit('changed', this.formatValue(val))
      },
      shouldValueBeFormatted(val) {
        return val.toString() !== val.toString();
      },
      formatValue(val) {
        if (this.valueType === 'integer') {
          val = parseInt(val);
        }
        if (this.valueType === 'float') {
          val = parseFloat(val);
        }
        if (this.type === 'number' && Number.isNaN(val)) {
          val = null;
        }
        return val;
      },
    },
  }
</script>
