import { computed } from '@vue/composition-api'
import store from '../store'

export function useLoading () {
  const circular = computed({
    get: () => store.getters['loading/circular'],
    set: val => store.dispatch('loading/setCircular', val)
  })
  return { circular }
}
