<template lang="pug">
  div
    v-data-table(
      v-if="providers.length"
      :headers="headers"
      :items="providers"
      :items-per-page="15"
      class="elevation-1"
    )
      template(
        v-slot:item.code="{ item }"
      )
        div.d-flex.py-6
          v-chip.mr-2.mt-1(
            :color="item.direction.code === 'sell' ? 'green' : 'warning'"
            outlined
            x-small
            style="width:90px;"
          ) {{ $t(`direction.${item.direction.code}`) }}
          div {{ $t(`provider.${item.code}`) }}
      template(
        v-slot:item.enabled="{ item }"
      )
        v-icon(
          v-if="item.settings.provider_enabled.value"
          color="success"
        ) check
        v-icon(
          v-else
          color="error"
        ) close
      template(
        v-slot:item.course="{ item }"
      )
        div(
          v-if="item.course && item.course.price !== 0"
        )
          div.font-weight-thin(
            style="font-size:10px"
          ) {{ item.course.author }}
          number-column(
            :value="item.course.price"
            color="warning"
          )
        div.grey--text(
          v-else
        ) нет данных
      template(
        v-slot:item.finalPrice="{ item }"
      )
        div(
          v-if="item.finalPrice"
        )
          div.font-weight-thin(
            style="font-size:10px"
          ) {{ item.course.createdAt | moment("from", "now") }}
          number-column(
            :value="item.finalPrice"
            color="green"
          )
        div.grey--text(
          v-else
        ) нет данных
      template(
        v-slot:item.correctionPercent="{ item }"
      )
        setting-value-column(
          :value="item.settings.course_correction_percent.value"
        )
      template(
        v-slot:item.priority="{ item }"
      )
        setting-value-column(
          :value="item.settings.provider_priority.value"
        )
      template(
        v-slot:item.action="{ item }"
      )
        v-tooltip(
          top
        )
          template(
            v-slot:activator="{ on, attrs }"
          )
            v-icon.mx-1(
              v-on="on"
              @click="onSettingsIconClick(item)"
            ) settings
          span Настройки
    v-dialog(
      v-model="settingsDialog"
      v-if="provider"
      width="500"
    )
      v-card(

      )
        v-card-title.text-h5.grey.lighten-2 {{ `Настройки: ${$t(`direction.${provider.direction.code}`)} ${this.$t(`provider.${provider.code}`)}` }}
        v-card-text
          settings-form(
            group="provider"
            :provider="provider.code"
            :direction="provider.direction.code"
            :currency="provider.currency",
            @saved="onSettingsSavedHandler"
          )
</template>

<script>
import NumberColumn from "../../components/view/columns/NumberColumn";
import SettingsForm from "../settings/SettingsForm";
import SettingValueColumn from "../view/columns/SettingValueColumn";

export default {
  name: 'ProviderGrid',
  components: {SettingValueColumn, SettingsForm, NumberColumn},
  props: {
    currency: {
      type: String,
    },
  },
  data() {
    return {
      providers: [],
      settingsDialog: false,
      provider: null,
    };
  },
  computed: {
    headers() {
      return [
        {text: 'Дествие', value: 'action', width: 150, align: 'center', sortable: false},
        {text: 'Название', value: 'code'},
        {text: this.providers[0].settings.provider_enabled.name, value: 'enabled', align: 'center'},
        {text: this.providers[0].settings.provider_priority.name, value: 'priority', align: 'center'},
        {text: 'Текущий курс', value: 'course', align: 'center'},
        {text: this.providers[0].settings.course_correction_percent.name, value: 'correctionPercent', align: 'center'},
        {text: 'Финальный курс', value: 'finalPrice', align: 'center'},
      ];
    },
  },
  mounted() {
    this.fetchProviders();
  },
  watch: {
    settingsDialog(val) {
      if (!val) {
        this.provider = null;
      }
    },
    currency() {
      this.fetchProviders();
    },
  },
  methods: {
    fetchProviders() {
      return this.$axios.get(`/provider/${this.currency}`).then(({data: {data: providers}}) => {
        this.providers = providers;
      });
    },
    onSettingsIconClick(provider) {
      this.provider = provider;
      this.settingsDialog = true;
    },
    onSettingsSavedHandler() {
      this.settingsDialog = false;
      this.fetchProviders();
    },
  },
}
</script>
