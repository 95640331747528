<template lang="pug">
  settings-form(
    group="provider"
    :provider="provider"
    :direction="direction"
    currency="RUB",
  )
</template>

<script>
import { useNav } from "../../composition/nav"
import SettingsForm from "../../components/settings/SettingsForm";

export default {
  name: 'ProviderSettings',
  components: {SettingsForm},
  mounted() {
    const { navTitle } = useNav();
    navTitle.value = `${this.$t(`direction.${this.direction}`)} ${this.$t(`provider.${this.provider}`)} - настройки`;
  },
  computed: {
    provider() {
      return this.$route.params.provider;
    },
    direction() {
      return this.$route.params.direction;
    },
  },
}
</script>
