import { computed } from '@vue/composition-api'
import store from '../store'

export function useNav () {
  const navTitle = computed({
    get: () => store.getters['nav/title'],
    set: val => store.dispatch('nav/setTitle', val)
  })
  return {
    navTitle,
    navItems: computed(() => store.getters['nav/navItems']),
    rubSettings: computed(() => store.getters['nav/rubSettings']),
    rubSimSettings: computed(() => store.getters['nav/rubSimSettings']),
    qiwiBtcSettings: computed(() => store.getters['nav/qiwiBtcSettings']),
    usdtBtcSettings: computed(() => store.getters['nav/usdtBtcSettings']),
    rubUsdtSettings: computed(() => store.getters['nav/rubUsdtSettings']),
    rubUsdSettings: computed(() => store.getters['nav/rubUsdSettings']),
    usdEurSettings: computed(() => store.getters['nav/usdEurSettings']),
    usdEthSettings: computed(() => store.getters['nav/usdEthSettings']),
    usdBtcSettings: computed(() => store.getters['nav/usdBtcSettings']),
    ethBtcSettings: computed(() => store.getters['nav/ethBtcSettings']),
    wmzBtcSettings: computed(() => store.getters['nav/wmzBtcSettings']),
    usdcBtcSettings: computed(() => store.getters['nav/usdcBtcSettings']),
    rubcashEthSettings: computed(() => store.getters['nav/rubcashEthSettings']),
    rubcashWmzSettings: computed(() => store.getters['nav/rubcashWmzSettings']),
    rubcashUsdcSettings: computed(() => store.getters['nav/rubcashUsdcSettings']),
    usdtEthSettings: computed(() => store.getters['nav/usdtEthSettings']),
    usdtWmzSettings: computed(() => store.getters['nav/usdtWmzSettings']),
    usdtUsdcSettings: computed(() => store.getters['nav/usdtUsdcSettings']),
    wmzEthSettings: computed(() => store.getters['nav/wmzEthSettings']),
    rubBtcGxSettings: computed(() => store.getters['nav/rubBtcGxSettings']),
    sberRubXmrSettings: computed(() => store.getters['nav/sberRubXmrSettings']),
    tbRubXmrSettings: computed(() => store.getters['nav/tbRubXmrSettings']),
    accRubXmrSettings: computed(() => store.getters['nav/accRubXmrSettings']),
    btcXmrSettings: computed(() => store.getters['nav/btcXmrSettings']),
    accRubBtcSettings: computed(() => store.getters['nav/accRubBtcSettings']),
    btcTrxSettings: computed(() => store.getters['nav/btcTrxSettings']),
    trxRubSettings: computed(() => store.getters['nav/trxRubSettings']),
    trxXmrSettings: computed(() => store.getters['nav/trxXmrSettings']),
    trxSbpSettings: computed(() => store.getters['nav/trxSbpSettings']),
    trxAccRubSettings: computed(() => store.getters['nav/trxAccRubSettings']),
    trxUsdcSettings: computed(() => store.getters['nav/trxUsdcSettings']),
    trxUsdtSettings: computed(() => store.getters['nav/trxUsdtSettings']),
    usdUsdtSettings: computed(() => store.getters['nav/usdUsdtSettings']),
    usdUsdcSettings: computed(() => store.getters['nav/usdUsdcSettings']),
    xmrUsdSettings: computed(() => store.getters['nav/xmrUsdSettings']),
    trxUsdSettings: computed(() => store.getters['nav/trxUsdSettings']),
    usdcRubSettings: computed(() => store.getters['nav/usdcRubSettings']),
    xmrUsdtSettings: computed(() => store.getters['nav/xmrUsdtSettings']),
    xmrUsdcSettings: computed(() => store.getters['nav/xmrUsdcSettings']),
    cnyRubSettings: computed(() => store.getters['nav/cnyRubSettings']),
    cnyAccRubSettings: computed(() => store.getters['nav/cnyAccRubSettings']),
    cnyBtcSettings: computed(() => store.getters['nav/cnyBtcSettings']),
    cnyUsdSettings: computed(() => store.getters['nav/cnyUsdSettings']),
    cnyUsdtSettings: computed(() => store.getters['nav/cnyUsdtSettings']),
    cnyUsdcSettings: computed(() => store.getters['nav/cnyUsdcSettings']),
    cnyXmrSettings: computed(() => store.getters['nav/cnyXmrSettings']),
    cnyTrxSettings: computed(() => store.getters['nav/cnyTrxSettings']),
    usdtAccRubSettings: computed(() => store.getters['nav/usdtAccRubSettings']),
    tusdRubSettings: computed(() => store.getters['nav/tusdRubSettings']),
    tusdAccRubSettings: computed(() => store.getters['nav/tusdAccRubSettings']),
    tusdBtcSettings: computed(() => store.getters['nav/tusdBtcSettings']),
    tusdUsdSettings: computed(() => store.getters['nav/tusdUsdSettings']),
    tusdUsdtSettings: computed(() => store.getters['nav/tusdUsdtSettings']),
    tusdUsdcSettings: computed(() => store.getters['nav/tusdUsdcSettings']),
    tusdXmrSettings: computed(() => store.getters['nav/tusdXmrSettings']),
    tusdTrxSettings: computed(() => store.getters['nav/tusdTrxSettings']),
    tusdCnySettings: computed(() => store.getters['nav/tusdCnySettings']),
    alpCnyRubSettings: computed(() => store.getters['nav/alpCnyRubSettings']),
    alpCnyAccRubSettings: computed(() => store.getters['nav/alpCnyAccRubSettings']),
    alpCnyBtcSettings: computed(() => store.getters['nav/alpCnyBtcSettings']),
    alpCnyUsdSettings: computed(() => store.getters['nav/alpCnyUsdSettings']),
    alpCnyUsdtSettings: computed(() => store.getters['nav/alpCnyUsdtSettings']),
    alpCnyUsdcSettings: computed(() => store.getters['nav/alpCnyUsdcSettings']),
    alpCnyTusdSettings: computed(() => store.getters['nav/alpCnyTusdSettings']),
    alpCnyXmrSettings: computed(() => store.getters['nav/alpCnyXmrSettings']),
    alpCnyTrxSettings: computed(() => store.getters['nav/alpCnyTrxSettings']),
    wctCnyRubSettings: computed(() => store.getters['nav/wctCnyRubSettings']),
    wctCnyAccRubSettings: computed(() => store.getters['nav/wctCnyAccRubSettings']),
    wctCnyBtcSettings: computed(() => store.getters['nav/wctCnyBtcSettings']),
    wctCnyUsdSettings: computed(() => store.getters['nav/wctCnyUsdSettings']),
    wctCnyUsdtSettings: computed(() => store.getters['nav/wctCnyUsdtSettings']),
    wctCnyUsdcSettings: computed(() => store.getters['nav/wctCnyUsdcSettings']),
    wctCnyTusdSettings: computed(() => store.getters['nav/wctCnyTusdSettings']),
    wctCnyXmrSettings: computed(() => store.getters['nav/wctCnyXmrSettings']),
    wctCnyTrxSettings: computed(() => store.getters['nav/wctCnyTrxSettings']),
    wireCnyRubSettings: computed(() => store.getters['nav/wireCnyRubSettings']),
    wireCnyAccRubSettings: computed(() => store.getters['nav/wireCnyAccRubSettings']),
    wireCnyBtcSettings: computed(() => store.getters['nav/wireCnyBtcSettings']),
    wireCnyUsdSettings: computed(() => store.getters['nav/wireCnyUsdSettings']),
    wireCnyUsdtSettings: computed(() => store.getters['nav/wireCnyUsdtSettings']),
    wireCnyUsdcSettings: computed(() => store.getters['nav/wireCnyUsdcSettings']),
    wireCnyTusdSettings: computed(() => store.getters['nav/wireCnyTusdSettings']),
    wireCnyXmrSettings: computed(() => store.getters['nav/wireCnyXmrSettings']),
    wireCnyTrxSettings: computed(() => store.getters['nav/wireCnyTrxSettings']),
    cashCnyRubSettings: computed(() => store.getters['nav/cashCnyRubSettings']),
    cashCnyAccRubSettings: computed(() => store.getters['nav/cashCnyAccRubSettings']),
    cashCnyBtcSettings: computed(() => store.getters['nav/cashCnyBtcSettings']),
    cashCnyUsdSettings: computed(() => store.getters['nav/cashCnyUsdSettings']),
    cashCnyUsdtSettings: computed(() => store.getters['nav/cashCnyUsdtSettings']),
    cashCnyUsdcSettings: computed(() => store.getters['nav/cashCnyUsdcSettings']),
    cashCnyTusdSettings: computed(() => store.getters['nav/cashCnyTusdSettings']),
    cashCnyXmrSettings: computed(() => store.getters['nav/cashCnyXmrSettings']),
    cashCnyTrxSettings: computed(() => store.getters['nav/cashCnyTrxSettings']),
    cashUsdRubSettings: computed(() => store.getters['nav/cashUsdRubSettings']),
    cashUsdAccRubSettings: computed(() => store.getters['nav/cashUsdAccRubSettings']),
    cashUsdBtcSettings: computed(() => store.getters['nav/cashUsdBtcSettings']),
    cashUsdUsdSettings: computed(() => store.getters['nav/cashUsdUsdSettings']),
    cashUsdUsdtSettings: computed(() => store.getters['nav/cashUsdUsdtSettings']),
    cashUsdUsdcSettings: computed(() => store.getters['nav/cashUsdUsdcSettings']),
    cashUsdTusdSettings: computed(() => store.getters['nav/cashUsdTusdSettings']),
    cashUsdXmrSettings: computed(() => store.getters['nav/cashUsdXmrSettings']),
    cashUsdTrxSettings: computed(() => store.getters['nav/cashUsdTrxSettings']),
    cashUsdCardCnySettings: computed(() => store.getters['nav/cashUsdCardCnySettings']),
    cashUsdAlpCnySettings: computed(() => store.getters['nav/cashUsdAlpCnySettings']),
    cashUsdWctCnySettings: computed(() => store.getters['nav/cashUsdWctCnySettings']),
    cashUsdWireCnySettings: computed(() => store.getters['nav/cashUsdWireCnySettings']),
    cashUsdCashCnySettings: computed(() => store.getters['nav/cashUsdCashCnySettings']),
    wireUsdRubSettings: computed(() => store.getters['nav/wireUsdRubSettings']),
    wireUsdAccRubSettings: computed(() => store.getters['nav/wireUsdAccRubSettings']),
    wireUsdBtcSettings: computed(() => store.getters['nav/wireUsdBtcSettings']),
    wireUsdUsdSettings: computed(() => store.getters['nav/wireUsdUsdSettings']),
    wireUsdUsdtSettings: computed(() => store.getters['nav/wireUsdUsdtSettings']),
    wireUsdUsdcSettings: computed(() => store.getters['nav/wireUsdUsdcSettings']),
    wireUsdTusdSettings: computed(() => store.getters['nav/wireUsdTusdSettings']),
    wireUsdXmrSettings: computed(() => store.getters['nav/wireUsdXmrSettings']),
    wireUsdTrxSettings: computed(() => store.getters['nav/wireUsdTrxSettings']),
    wireUsdCardCnySettings: computed(() => store.getters['nav/wireUsdCardCnySettings']),
    wireUsdAlpCnySettings: computed(() => store.getters['nav/wireUsdAlpCnySettings']),
    wireUsdWctCnySettings: computed(() => store.getters['nav/wireUsdWctCnySettings']),
    wireUsdWireCnySettings: computed(() => store.getters['nav/wireUsdWireCnySettings']),
    wireUsdCashCnySettings: computed(() => store.getters['nav/wireUsdCashCnySettings']),
    cashRubRubSettings: computed(() => store.getters['nav/cashRubRubSettings']),
    cashRubAccRubSettings: computed(() => store.getters['nav/cashRubAccRubSettings']),
    cashRubBtcSettings: computed(() => store.getters['nav/cashRubBtcSettings']),
    cashRubUsdSettings: computed(() => store.getters['nav/cashRubUsdSettings']),
    cashRubCashUsdSettings: computed(() => store.getters['nav/cashRubCashUsdSettings']),
    cashRubWireUsdSettings: computed(() => store.getters['nav/cashRubWireUsdSettings']),
    cashRubUsdtSettings: computed(() => store.getters['nav/cashRubUsdtSettings']),
    cashRubUsdcSettings: computed(() => store.getters['nav/cashRubUsdcSettings']),
    cashRubTusdSettings: computed(() => store.getters['nav/cashRubTusdSettings']),
    cashRubXmrSettings: computed(() => store.getters['nav/cashRubXmrSettings']),
    cashRubTrxSettings: computed(() => store.getters['nav/cashRubTrxSettings']),
    cashRubCardCnySettings: computed(() => store.getters['nav/cashRubCardCnySettings']),
    cashRubAlpCnySettings: computed(() => store.getters['nav/cashRubAlpCnySettings']),
    cashRubWctCnySettings: computed(() => store.getters['nav/cashRubWctCnySettings']),
    cashRubWireCnySettings: computed(() => store.getters['nav/cashRubWireCnySettings']),
    cashRubCashCnySettings: computed(() => store.getters['nav/cashRubCashCnySettings']),
    wireRubRubSettings: computed(() => store.getters['nav/wireRubRubSettings']),
    wireRubAccRubSettings: computed(() => store.getters['nav/wireRubAccRubSettings']),
    wireRubCashRubSettings: computed(() => store.getters['nav/wireRubCashRubSettings']),
    wireRubBtcSettings: computed(() => store.getters['nav/wireRubBtcSettings']),
    wireRubUsdSettings: computed(() => store.getters['nav/wireRubUsdSettings']),
    wireRubCashUsdSettings: computed(() => store.getters['nav/wireRubCashUsdSettings']),
    wireRubWireUsdSettings: computed(() => store.getters['nav/wireRubWireUsdSettings']),
    wireRubUsdtSettings: computed(() => store.getters['nav/wireRubUsdtSettings']),
    wireRubUsdcSettings: computed(() => store.getters['nav/wireRubUsdcSettings']),
    wireRubTusdSettings: computed(() => store.getters['nav/wireRubTusdSettings']),
    wireRubXmrSettings: computed(() => store.getters['nav/wireRubXmrSettings']),
    wireRubTrxSettings: computed(() => store.getters['nav/wireRubTrxSettings']),
    wireRubCardCnySettings: computed(() => store.getters['nav/wireRubCardCnySettings']),
    wireRubAlpCnySettings: computed(() => store.getters['nav/wireRubAlpCnySettings']),
    wireRubWctCnySettings: computed(() => store.getters['nav/wireRubWctCnySettings']),
    wireRubWireCnySettings: computed(() => store.getters['nav/wireRubWireCnySettings']),
    wireRubCashCnySettings: computed(() => store.getters['nav/wireRubCashCnySettings']),
    usdtTcsbrubSettings: computed(() => store.getters['nav/usdtTcsbrubSettings']),
    btcTcsbrubSettings: computed(() => store.getters['nav/btcTcsbrubSettings']),
    xmrTcsbrubSettings: computed(() => store.getters['nav/xmrTcsbrubSettings']),
    trxTcsbrubSettings: computed(() => store.getters['nav/trxTcsbrubSettings']),
  }
}
