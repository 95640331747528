<template lang="pug">
  div
    span(
      v-if="light"
    ) {{ currencyFormat(value) }}
    v-chip(
      v-else
      dark
      :color="color"
      outlined
    ) {{ currencyFormat(value) }}
</template>

<script>
import { useFormatNumber } from "../../../composition/format-number";

export default {
  name: 'NumberColumn',
  setup () {
    const { currencyFormat } = useFormatNumber();
    return {
      currencyFormat,
    }
  },
  props: {
    value: {},
    color: {
      default: 'primary'
    },
    light: {
      type: Boolean,
      default: false,
    },
  }
}
</script>
