const state = () => ({
  validation: [],
  error: null,
  warning: null,
  success: null
})

const mutations = {
  validation: (state, val) => {
    state.validation = val
  },
  error: (state, val) => {
    state.error = val
  },
  warning: (state, val) => {
    state.warning = val
  },
  success: (state, val) => {
    state.success = val
  }
}

const actions = {
  setValidation ({ commit }, val) {
    commit('validation', val)
  },
  setError ({ commit }, val) {
    commit('error', val)
  },
  setWarning ({ commit }, val) {
    commit('warning', val)
  },
  setSuccess ({ commit }, val) {
    commit('success', val)
  }
}

const getters = {
  validation: state => state.validation,
  error: state => state.error,
  warning: state => state.warning,
  success: state => state.success
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
