<template lang="pug">
  div
    v-tabs(
      v-model="directionTabModel"
    )
      v-tab(
        v-for="directionCode in directions"
        :key="directionCode"
      ) {{ tabTitleMap[directionCode] || $t(`direction.${directionCode}`) }}
    v-tabs-items(
      v-model="directionTabModel"
    )
      v-tab-item(
        v-for="directionCode in directions"
        :key="directionCode"
      )
        v-card.mt-6(
          flat
        )
          settings-form(
            :group="group"
            :currency="currency"
            :direction="direction"
            :provider="provider"
          )
</template>

<script>
import SettingsForm from "./SettingsForm";

export default {
  name: 'Settings',
  props: {
    group: {
      type: String,
      required: true,
    },
    provider: {
      type: String,
      default: null,
    },
    tabTitleMap: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      directions: [],
      directionTabModel: 0,
    }
  },
  computed: {
    direction() {
      return this.directions[this.directionTabModel];
    },
    currency() {
      return this.$route.params.currency;
    },
  },
  components: {
    SettingsForm,
  },
  async created() {
    await this.fetchDirections();
  },
  methods: {
    fetchDirections() {
      return this.$axios.get('/direction').then(({data: {data: directions}}) => {
        this.directions = directions.direction;
      });
    },
  },
  watch: {
    currency() {
      this.fetchDirections();
    }
  }
}
</script>
