import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ru from '../translations/ru'

Vue.use(VueI18n);

export default new VueI18n({
    locale: 'ru',
    fallbackLocale: 'en',
    messages: { ru },
})
