const state = () => ({
  circular: false
})

const mutations = {
  circular: (state, val) => {
    state.circular = val
  }
}

const actions = {
  setCircular ({ commit }, val) {
    commit('circular', val)
  }
}

const getters = {
  circular: state => state.circular
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
