"use strict";

import Vue from 'vue';
import axios from "axios";
import store from '../store'
import { queryString } from 'object-query-string';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
axios.defaults.headers.common['Content-Type'] = 'application/json';

let config = {
  baseURL: process.env.VUE_APP_API_BASE_URL
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    store.dispatch('message/setValidation', [])
    store.dispatch('message/setError', null)
    store.dispatch('loading/setCircular', true)
    config.paramsSerializer = (params) => queryString(params);
    if (store.getters['auth/token']) {
      config.headers.Authorization = 'Bearer ' + store.getters['auth/token']
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    store.dispatch('loading/setCircular', false)
    return response;
  },
  function(error) {
    store.dispatch('loading/setCircular', false)
    const errorCode = error.response ? error.response.status : 0
    if (errorCode === 422) {
      store.dispatch('message/setValidation', error.response.data.data)
    } else if (errorCode === 401) {
      store.dispatch('auth/clearAuth')
    } else if (errorCode === 403) {
      store.dispatch('message/setError', 'Доступ запрещен.')
    } else if (errorCode === 404) {
      //
    } else if (errorCode === 406) {
      store.dispatch('message/setWarning', error.response.data.data.message)
    } else {
      store.dispatch('message/setError', 'Возникла непредвиденная ошибка.')
    }

    return Promise.reject(error);
  }
);

Plugin.install = function(Vue/*, options*/) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin);

export default Plugin;
