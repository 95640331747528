import { computed } from '@vue/composition-api'
import store from '../store'

export function useValidation () {
  const validationErrors = computed({
    get: () => store.getters['message/validation'],
    set: val => store.dispatch('message/setValidation', val)
  })
  return { validationErrors }
}
