import Vue from 'vue'
import VueRouter from 'vue-router'
import guest from './middleware/guest'
import auth from './middleware/auth'
import store from '../store'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    // component: () => import('../views/provider/ProviderList.vue'),
    meta: {
      middleware: [
        auth,
      ]
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      middleware: [
        guest,
      ]
    },
  },
  {
    path: '/settings-course/:currency',
    name: 'settings_course',
    component: () => import('../views/settings/CourseSettings.vue'),
    meta: {
      middleware: [
        auth,
      ]
    },
  },
  {
    path: '/settings-general',
    name: 'settings_general',
    component: () => import('../views/settings/GeneralSettings.vue'),
    meta: {
      middleware: [
        auth,
      ]
    },
  },
  {
    path: '/provider/:currency',
    name: 'provider',
    component: () => import('../views/provider/ProviderList.vue'),
    meta: {
      middleware: [
        auth,
      ]
    },
  },
  {
    path: '/provider/:provider/settings/:direction',
    name: 'provider_settings',
    component: () => import('../views/provider/ProviderSettings.vue'),
    meta: {
      middleware: [
        auth,
      ]
    },
  },
  {
    path: '*',
    name: 'error_404',
    component: () => import('../views/http-error/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  store.dispatch('auth/fetchAuth');

  if (!(to.meta || {}).middleware) {
    return next()
  }

  const context = {
    to,
    from,
    next,
    store
  };

  return to.meta.middleware[0]({
    ...context
  })
});

export default router
